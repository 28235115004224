import Text from 'components/Text';

type Props = {
  containerClassName?: string;
  className?: string;
  textColor?: `text-${string}`;
  fontSize?: number;
  bold?: boolean;
  label?: string;
  value: string | number;
  onChecked: (value: string | number) => void;
  isChecked?: boolean;
};

function RadioButton({
  containerClassName,
  className = '',
  textColor = 'text-black',
  fontSize,
  bold = false,
  label,
  value,
  onChecked,
  isChecked,
}: Props) {
  return (
    <div
      className={`${containerClassName} flex items-center gap-2 w-fit cursor-pointer`}
      onClick={() => onChecked(value)}
    >
      <div
        className={`${className} w-5 aspect-square rounded-full ${
          isChecked
            ? 'bg-primary ring-primary border-4 border-solid border-white'
            : 'bg-white ring-neutral-200'
        } ring-1 cursor-pointer`}
      ></div>
      {label && (
        <Text bold={bold} textColor={textColor} fontSize={fontSize}>
          {label}
        </Text>
      )}
    </div>
  );
}

export default RadioButton;
