import Icon from 'components/Icon';
import { LIMIT_VALUE, TEXT_STRING } from '../../constants';
import { EPaymentMethod } from 'types';
import Text from 'components/Text';
import TextField from 'components/TextField';
import { useCallback, useMemo, useState } from 'react';
import { convertFormatAmount, getFormattedAmount } from 'utils';
import Button from 'components/Button';
import { useToast } from 'hooks';

const paymentStr = TEXT_STRING.PAYMENT;
const commonStr = TEXT_STRING.COMMON;
const errorStr = TEXT_STRING.ERROR;

type Props = {
  paymentMethod: EPaymentMethod;
  amount: number;
  className?: string;
  onPayment: () => void;
  loading?: boolean;
  onChangeAmount: (amount: number) => void;
  remainingAmount: number;
};

const method: Record<string, Record<string, string>> = {
  [EPaymentMethod.CASH]: {
    label: paymentStr.CASH,
    icon: 'money',
  },
  [EPaymentMethod.POINT]: {
    label: paymentStr.POINT_ASSET,
    icon: 'teco',
  },
  [EPaymentMethod.CARD]: {
    label: `${paymentStr.CREDIT_CARD}${TEXT_STRING.RELEASE_GIRL.HANDLING_FEE}`,
    icon: 'creditCard',
  },
};

const PaymentAmount = ({
  paymentMethod,
  className = '',
  onPayment,
  loading = false,
  amount,
  onChangeAmount,
  remainingAmount,
}: Props): JSX.Element => {
  // Hooks
  const { toastError } = useToast();

  // States
  const [isFocus, setIsFocus] = useState<boolean>(false);

  // Memo, Callbacks
  const remaining = useMemo(
    () => remainingAmount - +amount,
    [amount, remainingAmount]
  );

  const handleSubmitAmount = useCallback(() => {
    if (
      amount < LIMIT_VALUE.MIN_AMOUNT_CARD &&
      paymentMethod === EPaymentMethod.CARD
    ) {
      return toastError(
        new Error(
          errorStr.MINIUM_AMOUNT.replace(
            '$amount',
            `${LIMIT_VALUE.MIN_AMOUNT_CARD}`
          )
        )
      );
    }
    onPayment();
  }, [amount, onPayment, paymentMethod, toastError]);

  return (
    <>
      <div className={`${className} flex flex-col gap-6`}>
        <div className="flex items-center gap-2">
          <Icon icon={method[paymentMethod].icon} width={24} />
          <Text className="text-sm 2xs:text-base xs:text-lg">
            {method[paymentMethod]?.label + paymentStr.AMOUNT_SETTLED}
          </Text>
        </div>
        <div className="flex items-center gap-4">
          <TextField
            name={'amount'}
            value={
              isFocus
                ? convertFormatAmount(amount)
                : getFormattedAmount(+amount)
            }
            onChange={(e) => onChangeAmount(+e.target.value)}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            type="tel"
            inputClass="py-4"
          />
          <div className="pr-1 text-2xl font-bold">
            <span>{commonStr.YEN}</span>
          </div>
        </div>
        <div className="flex flex-col gap-4 border-b border-solid pb-4">
          <div className="flex gap-4 items-center">
            <Text className="text-sm 2xs:text-base xs:text-lg">
              {paymentStr.REMAINING_BALANCE}
            </Text>
            <Text
              bold
              className="text-sm 2xs:text-base xs:text-lg flex items-center"
            >
              {getFormattedAmount(remaining, commonStr.YEN)}
            </Text>
          </div>
        </div>
        <Button
          onClick={handleSubmitAmount}
          disabled={(!amount && !!remaining) || loading}
          loading={loading}
          className="mt-2"
        >
          {paymentStr.MAKE_PAYMENT}
        </Button>
      </div>
    </>
  );
};

export default PaymentAmount;
