import { CSSProperties, memo, ReactElement, useLayoutEffect, useRef, useState } from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader,
  List,
  ListRowProps,
  ScrollParams,
  defaultCellRangeRenderer,
  OverscanIndexRange,
  GridCellRangeProps,
} from 'react-virtualized';
import { RenderedRows } from 'react-virtualized/dist/es/List';

type Props = {
  fetchMore: () => void;
  hasMore: boolean;
  dataLength: number;
  listWidth?: number;
  listHeight?: number;
  rowHeight?: number;
  overscanRowCount?: number;
  rowCount: number;
  RowItem: (props: {
    index: number;
    measure: () => void;
    registerChild?: any;
    style: CSSProperties;
  }) => ReactElement;
  scrollTop?: number;
  onScroll?: (params: ScrollParams) => void;
  cache?: CellMeasurerCache;
  className?: string;
};

const cacheDefault = new CellMeasurerCache({
  defaultHeight: 50,
  fixedWidth: true,
});

const VirtualizedList = ({
  rowCount,
  rowHeight,
  listWidth,
  listHeight,
  RowItem,
  dataLength,
  fetchMore,
  hasMore,
  overscanRowCount = 5,
  scrollTop,
  onScroll,
  cache = cacheDefault,
  className = '',
}: Props) => {
  const [overscanStartIndex, setOverscanStartIndex] = useState(NaN);
  const [overscanStopIndex, setOverscanStopIndex] = useState(NaN);
  const tmpRef = useRef<HTMLDivElement>(null);
  const rowHeightsRef = useRef([] as number[]);

  useLayoutEffect(() => {

    const itemsContainer = tmpRef.current?.parentNode as HTMLDivElement | undefined | null;
    const rowHeights = rowHeightsRef.current;
    if (!itemsContainer || !rowHeights) return;

    const { children } = itemsContainer;

    for (let index = overscanStartIndex; index <= overscanStopIndex; index++) {
      const childIndex = index - overscanStartIndex;
      const child = children.item(childIndex);
      if (child === null || child.clientHeight === 0) {
        continue;
      }

      if (cache.getHeight(index, 0) === child.clientHeight) {
        rowHeights[index] = child.clientHeight;
      } else {
        rowHeights[index] = cache.getHeight(index, 0);
      }
    };

    const totalHeight = `${rowHeights.reduce((totalHeight, height) => totalHeight + height, 0)}px`;
    itemsContainer.style.height = totalHeight;
    itemsContainer.style.maxHeight = totalHeight;

  }, [overscanStartIndex, overscanStopIndex, cache]);


  const rowRenderer = ({ index, key, parent, style }: ListRowProps) => {
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ measure, registerChild }: any) => {
          return (
            <RowItem
              index={index}
              measure={measure}
              registerChild={registerChild}
              style={style}
            />
          );
        }}
      </CellMeasurer>
    );
  };

  const handleRowsRendered = ({
    overscanStartIndex,
    overscanStopIndex,
  }: OverscanIndexRange) => {
    setOverscanStartIndex(overscanStartIndex);
    setOverscanStopIndex(overscanStopIndex);
  }

  const getRowHeight = ({ index }: { index: number }) => {
    const rowHeights = rowHeightsRef.current;

    const height = rowHeights.at(index);

    if (typeof height === 'undefined') {
      return cache.getHeight(index, 0);
    } else {
      return height;
    }
  }

  const customCellRangeRenderer = (props: GridCellRangeProps) => {
    const children = defaultCellRangeRenderer({ ...props, cellCache: {} });
    children.push(<div ref={tmpRef} key="tmpElement" />);

    return children;
  };

  return (
    <InfiniteLoader
      isRowLoaded={({ index }) => !hasMore || index < dataLength}
      loadMoreRows={async () => fetchMore()}
      rowCount={rowCount}
    >
      {({ onRowsRendered, registerChild }) => (
        <>
          <AutoSizer>
            {({ height, width }) => {
              const customOnRowsRendered = (info: RenderedRows) => {
                onRowsRendered({ startIndex: info.startIndex, stopIndex: info.stopIndex });
                handleRowsRendered({ overscanStartIndex: info.overscanStartIndex, overscanStopIndex: info.overscanStopIndex });
              };
              return (
                <List
                  ref={registerChild}
                  width={listWidth || width}
                  height={listHeight || height}
                  onRowsRendered={customOnRowsRendered}
                  rowCount={rowCount}
                  rowHeight={rowHeight || getRowHeight}
                  rowRenderer={rowRenderer}
                  overscanRowCount={overscanRowCount}
                  scrollTop={scrollTop}
                  onScroll={onScroll}
                  className={className}
                  cellRangeRenderer={customCellRangeRenderer}
                />
              );
            }}
          </AutoSizer>
        </>
      )}
    </InfiniteLoader>
  );
};

export default memo(VirtualizedList);