import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { createManagementClientChannel, getFemaleList } from 'api';
import {
  Chip,
  FloatButton,
  Icon,
  ListDiscount,
  Loading,
  Tabs,
  Text,
  TextField,
} from 'components';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ESearchTab, TFemale, TSchedule } from 'types';
import CardItem from './CardItem';
import ModalFilter from './ModalFilter';
import {
  PAGE_SIZE,
  SESSION_KEYS,
  TEXT_STRING,
  queryKeys,
  routes,
} from '../../constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth, useFollow } from 'hooks';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getCurrentWeeks } from 'utils';
import moment from 'moment';
import Game from '../../assets/images/game.png';
import IntroVideo from '../../assets/images/introVideo.png';
import { useAppStore, useUserStore } from 'store';
import { toast } from 'react-toastify';

type TFilterTime = {
  startTime: string;
  endTime: string;
};

const listTab = [
  {
    title: TEXT_STRING.SEARCH_PAGE.SCHEDULES,
    id: ESearchTab.SCHEDULES,
  },
  {
    title: TEXT_STRING.SEARCH_PAGE.INDIVIDUAL_DISCOUNT_LIST,
    id: ESearchTab.LIST_DISCOUNT,
  },
  {
    title: TEXT_STRING.SEARCH_PAGE.LIST_EMPLOYEES,
    id: ESearchTab.LIST_EMPLOYEE,
  },
];
const currentWeeks = getCurrentWeeks({});
const bannerUrl = process.env.REACT_APP_HOME_BANNER_URL || '';

function Search() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = React.useMemo<ESearchTab>(
    () => (searchParams.get('tab') || ESearchTab.SCHEDULES) as ESearchTab,
    [searchParams]
  );

  // State
  const [open, setOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<TFilterTime>({
    startTime: currentWeeks[0].startDate,
    endTime: currentWeeks[0].startDate,
  });
  const [listView, setListView] = useState<{
    list: TFemale[];
    page: number;
  }>({
    list: [],
    page: 1,
  });

  // Hook
  const scrollY = useRef<number>(0);
  const { handleFollow, loadingFollow } = useFollow();
  const { checkNeedToLogin } = useAuth();
  const { filterFemale, setFilterFemale, searchScrollY, setSearchScrollY } =
    useAppStore();
  const { isAuthenticated, user, clientId, setMgmChannelId, mgmChannelId } =
    useUserStore();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const introVideoRef = useRef<
    HTMLVideoElement & {
      mozRequestFullScreen?: () => Promise<void>;
      webkitRequestFullscreen?: () => Promise<void>;
      msRequestFullscreen?: () => Promise<void>;
    }
  >(null);

  // Queries
  const {
    data: listFemale,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [
      queryKeys.FEMALE_LIST,
      filterFemale,
      selectedDate,
      tab,
      // tabDiscount,
    ],
    queryFn: async ({ pageParam }) => {
      const isTabSchedules = tab === ESearchTab.SCHEDULES;
      const isTabDiscount = tab === ESearchTab.LIST_DISCOUNT;
      return getFemaleList({
        currentPage: pageParam,
        pageSize: PAGE_SIZE,
        filter: {
          ...filterFemale,
          discount: {
            singleCoupon: isTabDiscount,
            groupCoupon: false,
            // isTabDiscount && tabDiscount === ESearchTabDiscount.MULTIPLE,
          },
          schedules: {
            startTime: isTabSchedules ? selectedDate?.startTime : '',
            endTime: isTabSchedules ? selectedDate?.endTime : '',
          },
        },
      });
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, currentPage) => {
      return lastPage?.totalPage > currentPage ? currentPage + 1 : undefined;
    },
  });

  // Mutation
  const { mutateAsync: createMutate, isPending } = useMutation({
    mutationFn: () => {
      return createManagementClientChannel(clientId || '', false);
    },
    onSuccess(res) {
      if (res) {
        setMgmChannelId(res.id);
      }
    },
    onError(err) {
      toast.error(err.message);
    },
    retry: 0,
  });

  // Callbacks
  const onFollowFemale = useCallback(
    (female: TFemale) => {
      if (!checkNeedToLogin()) {
        handleFollow(female?.userId, female.isFollowed);
        const femaleWithUserId = listView.list.findIndex(
          (obj) => obj.userId === female.userId
        );
        listView.list[femaleWithUserId].isFollowed = !female.isFollowed;
        setListView((prev) => ({ ...prev, list: listView.list }));
      }
    },
    [checkNeedToLogin, handleFollow, listView]
  );

  const handleSelectedDate = useCallback((day: TSchedule) => {
    setSelectedDate(() => ({
      startTime: day.startDate,
      endTime: day.startDate,
    }));
  }, []);

  const handleChatWithAdmin = useCallback(async () => {
    if (isPending) return;
    let channelId: string | undefined;
    if (isAuthenticated) {
      channelId = user?.managementChannelId;
    } else {
      try {
        channelId = mgmChannelId || (await createMutate())?.id;
      } catch (error) {}
    }

    if (channelId) {
      navigate(routes.CONVERSATION.replace(':id', channelId));
    }
  }, [
    isPending,
    isAuthenticated,
    createMutate,
    mgmChannelId,
    navigate,
    user?.managementChannelId,
  ]);

  const handleScroll = useCallback((e: MouseEvent) => {
    scrollY.current = (e.target as HTMLElement)?.scrollTop || 0;
  }, []);

  const handleOpenIntroVideo = () => {
    const introRef = introVideoRef.current;
    if (introRef?.requestFullscreen) {
      introRef.requestFullscreen();
    } else if (introRef?.mozRequestFullScreen) {
      // Firefox
      introRef.mozRequestFullScreen();
    } else if (introRef?.webkitRequestFullscreen) {
      // Safari
      introRef.webkitRequestFullscreen();
    } else if (introRef?.msRequestFullscreen) {
      // IE/Edge
      introRef.msRequestFullscreen();
    }
    introRef?.play();
  };

  // Effect
  useEffect(() => {
    const sessionTab = sessionStorage.getItem(SESSION_KEYS.SEARCH_TAB);
    if (sessionTab) {
      setSearchParams({
        tab: sessionTab,
      });
    }
  }, [setSearchParams]);

  useEffect(() => {
    if (listFemale?.pages?.length) {
      setListView((prev) => {
        const listData =
          prev.page >= listFemale?.pages.length || prev.page === 1
            ? JSON.parse(
                JSON.stringify(listFemale?.pages.map(({ data }) => data).flat())
              )
            : [...prev.list, ...(listFemale?.pages.slice(-1)[0].data ?? [])];

        return { list: listData, page: listFemale?.pages.length };
      });
    }
  }, [listFemale?.pages, setListView]);

  useEffect(() => {
    document.addEventListener('fullscreenchange', () => {
      const introRef = introVideoRef.current;
      if (
        !document.fullscreenElement &&
        !introRef?.paused &&
        !introRef?.ended
      ) {
        introRef?.pause();
      }
    });
  }, []);

  return (
    <>
      <div className="overflow-y-auto h-full" id="scrollFemale">
        <div className="flex gap-[8px] pt-16px px-8px bg-white">
          <div>
            <img
              onClick={() => {
                navigate(routes.GAME_PAGE);
              }}
              src={Game}
              alt=""
              className="w-full cursor-pointer"
            />
          </div>
          <div className="relative">
            <img
              onClick={handleOpenIntroVideo}
              src={IntroVideo}
              alt=""
              className="w-full cursor-pointer"
            />
            {bannerUrl && (
              <video
                controls
                ref={introVideoRef}
                className="absolute z-[-1] top-0 left-0 h-0 w-0"
              >
                <source src={bannerUrl} />
              </video>
            )}
          </div>
        </div>
        <ListDiscount onFollow={onFollowFemale} />
        {/* Tabs and filter */}
        <div className="sticky top-0 z-[1] bg-white flex flex-col gap-16px pt-16px px-8px w-full">
          <Tabs
            currentTab={tab}
            tabs={listTab}
            onChangeTab={(tab: string) => {
              sessionStorage.setItem(SESSION_KEYS.SEARCH_TAB, tab);
              setSearchParams({ tab });
            }}
          />
          <div onClick={() => setOpen(true)} className="cursor-pointer">
            <TextField
              prefix={
                <div>
                  <Icon icon={'searchInput'} color="var(--color-neutral-300)" />
                </div>
              }
              name={''}
              value={''}
              onChange={(_event: React.ChangeEvent<Element>): void => {}}
              placeholder={TEXT_STRING.SEARCH_PAGE.SET_CONDITIONS}
              className="w-full pointer-events-none pl-10px"
            />
          </div>
          {tab === ESearchTab.SCHEDULES && (
            <div className="grid grid-cols-7 pb-1 gap-1 justify-between overflow-x-auto w-full">
              {currentWeeks.map((day, index) => {
                const isSelectedDay = moment(day.startDate).isSame(
                  selectedDate.startTime
                );
                return (
                  <Chip
                    className="flex flex-col justify-center items-center cursor-pointer"
                    key={index}
                    ringColor={isSelectedDay ? 'ring-primary' : 'ring-neutral'}
                    bgColor={isSelectedDay ? 'bg-primary-50' : 'bg-white'}
                    onClick={() => handleSelectedDate(day)}
                    padding="p-3"
                  >
                    <Text textColor="text-neutral" center bold>
                      {day.startDateFormat}
                    </Text>
                  </Chip>
                );
              })}
            </div>
          )}
          <hr />
        </div>

        {/* List */}
        {isLoading ||
        queryClient.isFetching({ queryKey: [queryKeys.FILTER_OPTIONS] }) ? (
          <div className="mt-8">
            <Loading />
          </div>
        ) : (
          <>
            {!listView.list?.length ? (
              <Text bold fontSize={16} className="py-8px flex justify-center">
                {TEXT_STRING.COMMON.NO_DATA}
              </Text>
            ) : (
              <InfiniteScroll
                next={() => fetchNextPage()}
                hasMore={hasNextPage}
                loader={
                  <div className="py-[36px] flex justify-center items-center">
                    <Loading />
                  </div>
                }
                dataLength={listView.list.length}
                scrollThreshold={0.5}
                scrollableTarget="scrollFemale"
                onScroll={handleScroll}
                initialScrollY={searchScrollY}
              >
                <div
                  className={`grid ${
                    tab === ESearchTab.LIST_DISCOUNT
                      ? 'grid-cols-1'
                      : 'grid-cols-3'
                  } gap-x-8px gap-y-16px p-16px`}
                >
                  {listView?.list.map((female) => {
                    const isLoadingFollow = loadingFollow.includes(
                      female?.userId
                    );
                    return (
                      <CardItem
                        onFollow={() => onFollowFemale(female)}
                        onDetail={() => {
                          setSearchScrollY(scrollY?.current);
                          navigate(
                            `${routes.DETAIL_FEMALE.replace(
                              ':id',
                              `${female.userId}`
                            )}`
                          );
                        }}
                        disabledFollow={isLoadingFollow}
                        data={female}
                        key={female.id}
                        showMeeting={tab === ESearchTab.SCHEDULES}
                        showShiftTime={tab === ESearchTab.SCHEDULES}
                        discount={
                          tab === ESearchTab.LIST_DISCOUNT
                            ? female.singleDiscount
                            : undefined
                        }
                        startDate={selectedDate?.startTime}
                      />
                    );
                  })}
                </div>
              </InfiniteScroll>
            )}
          </>
        )}
      </div>

      {/* Contact the administrator */}
      <FloatButton
        isLoading={isPending}
        content={TEXT_STRING.COMMON.INQUIRY}
        icon={'headphone'}
        handleClick={handleChatWithAdmin}
      />
      <ModalFilter
        currentTab={tab}
        open={open}
        onClose={() => setOpen(false)}
        onSearch={(filters, search) => {
          setFilterFemale({ ...filterFemale, ...filters, keywords: search });
          setOpen(false);
        }}
        filtered={{
          activityAreas: filterFemale.activityAreas,
          ranks: filterFemale.ranks,
          options: filterFemale.options,
          starIds: filterFemale.starIds,
        }}
        keywords={filterFemale.keywords}
      />
    </>
  );
}

export default Search;
