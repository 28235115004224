import { TEXT_STRING } from '../../constants';
import Text from 'components/Text';
import { TFemale, TProjectInfo, TStarRank } from 'types';
import CardGirl from './CardGirl';
import OrderFooter from './OrderFooter';
import { formatDate } from '../../utils';
import Rating from 'components/Rating';

const CALL_WOMAN = TEXT_STRING.CALL_WOMAN;
const COMMON = TEXT_STRING.COMMON;
const ORDER_DETAIL = TEXT_STRING.ORDER_DETAIL;
const SUMMARY_ORDER = TEXT_STRING.SUMMARY_ORDER;

type Props = {
  onOrderGirl: () => void;
  summaryOrder: Record<string, string | number>;
  listGirl: TFemale[];
  estimatePrice: string;
  loading: boolean;
  listStarRank?: TStarRank[];
  projectInfo?: TProjectInfo;
};

const SummaryOrder = ({
  onOrderGirl,
  summaryOrder,
  listGirl,
  estimatePrice,
  loading,
  listStarRank = [],
  projectInfo,
}: Props): JSX.Element => {
  return (
    <div className="p-5 h-full flex flex-col gap-4 overflow-y-auto justify-between">
      <div className="flex flex-col gap-4">
        {/* TODO: Waiting for release date confirmation*/}
        {/* <div className="flex flex-col gap-8px">
          <Text textColor="text-neutral-400" fontSize={14}>
            {ORDER_DETAIL.HOW_TO_PLAY}
          </Text>
          <Text className="flex gap-8px" bold>
            <span className="[word-spacing:8px] text-[14px]">
              {
                projectInfo?.optionsType?.find(
                  (option) => option.value === summaryOrder?.orderType
                )?.name
              }
            </span>
          </Text>
        </div> */}
        <div className="flex flex-col gap-8px">
          <Text textColor="text-neutral-400" fontSize={14}>
            {CALL_WOMAN.LABEL_OPTIONS}
          </Text>
          <Text className="flex gap-8px" bold>
            <span className="[word-spacing:8px] text-[14px]">
              {`${summaryOrder?.setCount}${COMMON.SET} - ${summaryOrder?.peopleCount}${COMMON.PERSON}`}
            </span>
          </Text>
        </div>
        <div className="flex flex-col gap-8px">
          <Text textColor="text-neutral-400" fontSize={14}>
            {CALL_WOMAN.START_TIME}
          </Text>
          <Text className="flex gap-8px" bold>
            <span className="[word-spacing:8px] text-[14px]">
              {`${formatDate(summaryOrder?.date, 'LL')} - ${
                summaryOrder?.time
              }`}
            </span>
          </Text>
        </div>
        <div className="flex flex-col gap-8px">
          <Text textColor="text-neutral-400" fontSize={14}>
            {listGirl?.length || +summaryOrder?.peopleCount > 1
              ? COMMON.TECO_GIRL
              : COMMON.SPECIFY_STARS}
          </Text>
          <div className="flex gap-8px px-1 overflow-x-auto">
            <>
              {listGirl?.length ? (
                listGirl?.map((girl) => {
                  return (
                    <CardGirl
                      key={girl.id}
                      onChoose={() => {}}
                      onDetail={() => {}}
                      data={girl}
                      disabledChoose
                    />
                  );
                })
              ) : listStarRank?.length ? (
                listStarRank.map((rank) => (
                  <Rating value={rank.star} key={rank.id} isShowEmpty={false} />
                ))
              ) : (
                <Text bold fontSize={14}>
                  {TEXT_STRING.PRICE_LIST.LEAVE_IT}
                </Text>
              )}
            </>
          </div>
        </div>
        {summaryOrder?.meetingAddress && (
          <div className="flex flex-col gap-8px">
            <Text textColor="text-neutral-400" fontSize={14}>
              {ORDER_DETAIL.MEETING_ADDRESS}
            </Text>
            <Text bold fontSize={14} className="[word-spacing:8px]">
              {[summaryOrder?.location, summaryOrder?.meetingAddress]
                .filter((item) => !!item)
                .join(' - ')}
            </Text>
          </div>
        )}
        <div className="flex flex-col gap-8px">
          <Text textColor="text-neutral-400" fontSize={14}>
            {ORDER_DETAIL.NUMBER_CUSTOMER}
          </Text>
          <Text className="flex gap-8px" bold fontSize={14}>
            <span>
              {COMMON.MALE} {summaryOrder.maleCount || 0} {COMMON.PEOPLE}
            </span>
          </Text>
        </div>
        {!!summaryOrder?.note && (
          <div className="flex flex-col gap-8px">
            <Text textColor="text-neutral-400" fontSize={14}>
              {ORDER_DETAIL.NOTE_REQUEST}
            </Text>
            <Text bold fontSize={14}>
              {summaryOrder?.note}
            </Text>
          </div>
        )}
      </div>
      <OrderFooter
        estimatePrice={
          <div className="flex justify-center gap-8px">
            <Text fontSize={14}>
              {estimatePrice}
              {COMMON.YEN}
            </Text>
            <Text textColor="text-neutral" fontSize={14}>
              + {CALL_WOMAN.GAME_FEE}
            </Text>
          </div>
        }
        onSubmit={onOrderGirl}
        loading={loading}
        textWarning=""
        textButton={SUMMARY_ORDER.ORDER}
      />
    </div>
  );
};

export default SummaryOrder;
