import Text from 'components/Text';
import { TGames } from 'types';
import { getFormattedAmount, validateGamePlayManyTime } from 'utils';

type Props = {
  isActive?: boolean;
  onChoose: () => void;
  data: TGames;
  disabled?: boolean;
};

const CardGame = ({
  isActive = false,
  disabled = false,
  onChoose,
  data,
}: Props): JSX.Element => {
  return (
    <div
      className={`p-8px rounded-[8px] border ${
        isActive ? 'border-primary bg-primary-50' : 'border-neutral-200'
      } ${
        !disabled && 'cursor-pointer'
      } flex flex-col items-center justify-between gap-12px flex-shrink-0 relative`}
      onClick={() => !disabled && onChoose()}
    >
      {disabled && (
        <div className="absolute w-full h-full bg-neutral-50 opacity-60 top-0 left-0 rounded-[8px]"></div>
      )}
      <img
        alt=""
        src={data?.avatar}
        className="aspect-square object-cover w-[80%] rounded-t-[8px]"
        draggable="false"
      />
      <div>
        <Text center textColor={isActive ? 'text-primary' : 'text-neutral-700'}>
          {data.name}
        </Text>
        <Text center textColor="text-success">
          {validateGamePlayManyTime(data.type) && 1}
          {data.unit}
          {getFormattedAmount(data.price)}
          {data.currency}
        </Text>
      </div>
    </div>
  );
};

export default CardGame;
