import {
  CardPayment,
  PaymentAmount,
  Header,
  PageWrapper,
  Text,
  ModalPaymentSuccess,
} from 'components';
import { TEXT_STRING, queryKeys } from '../../constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getPendingPayment, payMoney } from 'api';
import {
  EPaymentMethod,
  TMoneyPaymentByCardBody,
  TPaymentPayload,
} from 'types';
import { useToast } from 'hooks';
import { useChatStore } from 'store';
import MethodPayment from 'components/PaymentMethod/PaymentMethod';
import { getFormattedAmount } from 'utils';

enum EPaymentStep {
  CHOOSE_METHOD = 1,
  ENTER_AMOUNT = 2,
  PAYMENT = 3,
}

const paymentStr = TEXT_STRING.PAYMENT;
const commonStr = TEXT_STRING.COMMON;

const Payment = () => {
  // Hooks
  const { id: paymentId } = useParams();
  const navigate = useNavigate();
  const { toastError } = useToast();
  const { setShowReview } = useChatStore();
  const queryClient = useQueryClient();

  // States
  const [paymentMethod, setPaymentMethod] = useState<EPaymentMethod>(
    EPaymentMethod.CASH
  );
  const [stepPayment, setStepPayment] = useState<EPaymentStep>(
    EPaymentStep.CHOOSE_METHOD
  );
  const [amountPayment, setAmountPayment] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);

  // Query
  const { data, isLoading, isRefetching, isError, error, refetch } = useQuery({
    queryKey: [queryKeys.PAYMENT, paymentId],
    queryFn: () => getPendingPayment(paymentId || ''),
    enabled: !!paymentId,
    retry: 0,
  });

  // Mutation
  const {
    mutateAsync,
    isPending,
    data: resPayment,
  } = useMutation({
    mutationFn: (body: TMoneyPaymentByCardBody) => {
      return payMoney(body);
    },
    onSuccess(data) {
      setOpen(true);
      if (data?.isFinished) {
        queryClient.refetchQueries({
          queryKey: [queryKeys.LIST_PENDING_PAYMENT],
        });
      } else {
        refetch();
      }
    },
    onError(err: any) {
      if (!err?.response?.data?.retry) {
        refetch();
        setStepPayment(EPaymentStep.CHOOSE_METHOD);
        setAmountPayment(0);
      }
    },
  });

  // Memo, callback
  const allowPaymentMethod: EPaymentMethod[] = useMemo(() => {
    return data?.allowPaymentMethod;
  }, [data?.allowPaymentMethod]);

  const handlePayment = useCallback(
    async (payload?: TPaymentPayload) => {
      const { token, customerId, type } = payload || {};
      if (data?.id && !!paymentMethod && !isPending) {
        await mutateAsync({
          paymentId: data?.id,
          token,
          customerId,
          type: type || paymentMethod,
          payFor: data?.payFor,
          amount: amountPayment,
        });
      }
    },
    [paymentMethod, isPending, mutateAsync, amountPayment, data]
  );

  const handleChangeAmount = useCallback(
    (amount: number) => {
      if (amount >= 0) {
        setAmountPayment(amount > +data?.amount ? +data?.amount : amount);
      }
    },
    [data?.amount]
  );

  const handleMethodPayment = useCallback(async () => {
    try {
      if ([EPaymentMethod.CASH, EPaymentMethod.POINT].includes(paymentMethod)) {
        await handlePayment();
      } else {
        setStepPayment(EPaymentStep.PAYMENT);
      }
    } catch (error) {
      toastError(error as Error);
    }
  }, [paymentMethod, handlePayment, toastError]);

  const onConfirmSuccess = useCallback(() => {
    if (resPayment?.isFinished) {
      navigate(-1);
      setShowReview(true);
    } else {
      setStepPayment(EPaymentStep.CHOOSE_METHOD);
    }
    setAmountPayment(0);
    setOpen(false);
  }, [navigate, resPayment?.isFinished, setShowReview]);

  const handleBack = useCallback(() => {
    if (data?.isPaymentRequest || stepPayment === EPaymentStep.CHOOSE_METHOD)
      navigate(-1);
    setStepPayment((prev) => prev - 1);
  }, [data?.isPaymentRequest, navigate, stepPayment]);

  // Effects
  useEffect(() => {
    if (data?.isPaymentRequest) {
      setPaymentMethod(EPaymentMethod.CARD);
      setStepPayment(EPaymentStep.PAYMENT);
      setAmountPayment(data?.amount);
    }
  }, [data?.amount, data?.isPaymentRequest]);

  return (
    <div className="h-full overflow-y-auto">
      <Header
        title={
          <Text bold className="text-sm">
            {TEXT_STRING.CHAT.RELEASE_TECO_GIRL}
          </Text>
        }
        onBack={handleBack}
        disabled={isPending}
      />
      <PageWrapper loading={isLoading} height="h-auto" className="p-6">
        {isError ? (
          <Text center className="py-10" textColor="text-error">
            {error?.message || TEXT_STRING.COMMON.SOMETHING_WENT_WRONG}
          </Text>
        ) : (
          <>
            <Text bold center className="text-lg mb-4">
              {data?.isPaymentRequest ? paymentStr.ADVANCE_PAYMENT : ''}
            </Text>
            <Text bold center className="text-sm">
              {stepPayment === EPaymentStep.ENTER_AMOUNT
                ? paymentStr.ENTER_AMOUNT
                : paymentStr.CHOOSE_PAYMENT_METHOD_PLEASE}
            </Text>
            <Text bold center className="text-3xl">
              {stepPayment === EPaymentStep.PAYMENT
                ? ''
                : getFormattedAmount(+data?.amount, commonStr.YEN)}
            </Text>
            {stepPayment === EPaymentStep.CHOOSE_METHOD && (
              <MethodPayment
                onChooseMethod={(method) => setPaymentMethod(method)}
                paymentMethod={paymentMethod}
                onSubmit={() => setStepPayment(EPaymentStep.ENTER_AMOUNT)}
                price={+data?.amount}
                allowPaymentMethod={allowPaymentMethod}
                loading={isPending || isRefetching}
              />
            )}
            {stepPayment === EPaymentStep.ENTER_AMOUNT && (
              <PaymentAmount
                paymentMethod={paymentMethod}
                className="mt-8"
                onPayment={handleMethodPayment}
                loading={isPending}
                onChangeAmount={handleChangeAmount}
                amount={amountPayment}
                remainingAmount={+data?.amount}
              />
            )}
            {stepPayment === EPaymentStep.PAYMENT && (
              <CardPayment
                title={''}
                price={amountPayment + amountPayment * data?.fee}
                onPay={handlePayment}
                paying={isPending}
              />
            )}
          </>
        )}
        <ModalPaymentSuccess
          open={open}
          paymentMethod={paymentMethod}
          onConfirm={onConfirmSuccess}
          amount={resPayment?.amount || 0}
          fee={data?.isPaymentRequest && data?.fee}
        />
      </PageWrapper>
    </div>
  );
};

export default Payment;
