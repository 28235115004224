import React from 'react';
import icons from './iconPath';

interface IconProps {
  icon: keyof typeof icons;
  color?: string;
  width?: number;
  height?: number;
}

const Icon = ({ icon, color, width, height }: IconProps): JSX.Element => {
  const IconPath = icons[icon];
  return (
    <>{IconPath && <IconPath color={color} width={width} height={height} />}</>
  );
};

export default Icon;
