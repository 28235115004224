import {
  DAY_IN_WEEK,
  START_DAY_WORK,
  TEXT_STRING,
  TIME_FORMAT,
  TIMEZONE,
} from '../../constants';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { TSchedule } from 'types';
import { formatDate, getCurrentWeeks } from 'utils';

type Props = {
  schedules: TSchedule[];
  area?: { id: number; name: string };
  isToBeConfirmed?: boolean;
  enableReadyToGo?: boolean;
};

const Spacing = () => {
  return <>&nbsp;</>;
};

const ScheduleItem = ({ text = '-' }: { text?: string }) => {
  return (
    <>
      <Spacing />
      <br />
      {text}
      <br />
      <Spacing />
    </>
  );
};

function ListSchedule({
  schedules,
  area,
  isToBeConfirmed,
  enableReadyToGo,
}: Props) {
  const [groupSchedule, setGroupSchedule] = useState<TSchedule[]>([]);

  useEffect(() => {
    setGroupSchedule(() => {
      if (!schedules?.length) {
        return getCurrentWeeks({});
      }
      const lastScheduleStartDate = moment(
        schedules[schedules.length - 1].startDate
      );

      const scheduleWithoutShift = getCurrentWeeks({
        currentDate: lastScheduleStartDate.clone().add({ days: 1, hours: 11 }),
        range: DAY_IN_WEEK - schedules.length,
      });
      return [...schedules, ...scheduleWithoutShift];
    });
  }, [schedules]);

  return (
    <div className="grid grid-cols-7 gap-x-2 mt-6">
      {groupSchedule?.map((schedule, index) => {
        const {
          startDate,
          startTime,
          endTime,
          disabled,
          startDateFormat,
          activityArea: shiftArea,
        } = schedule;
        const isShowGo = () => {
          const currentTime = moment().tz(TIMEZONE);
          const selectedDate = moment(startDate)
            .tz(TIMEZONE)
            .format('YYYY-MM-DD');

          if (
            !enableReadyToGo ||
            selectedDate !== currentTime.format('YYYY-MM-DD')
          )
            return false;

          if (!startTime) return enableReadyToGo;

          const startShift = moment.tz(
            `${selectedDate} ${startTime}`,
            'YYYY-MM-DD HH:mm',
            TIMEZONE
          );

          if (
            startShift.isBefore(
              moment.tz(START_DAY_WORK, TIME_FORMAT, TIMEZONE)
            )
          ) {
            startShift.add(1, 'day');
          }

          return currentTime.isBefore(startShift);
        };

        const showReadyToGo = isShowGo();
        const hasSettingWorking = showReadyToGo || isToBeConfirmed;

        return (
          <div
            key={index}
            className={`flex flex-col border border-solid border-neutral-300 rounded-xl font-medium text-neutral text-center`}
          >
            <div className="py-2 flex flex-col items-center gap-1">
              <span>
                {startDateFormat || formatDate(startDate, 'MM/DD\n (dd)')}
              </span>
            </div>
            <div
              className={`border-y border-solid border-neutral-300 py-2 flex-1 flex justify-center items-center
                ${
                  schedules.some((schedule) => !schedule.disabled) &&
                  'whitespace-pre-line'
                }
              `}
            >
              {(disabled || !startTime) && !hasSettingWorking ? (
                <ScheduleItem />
              ) : showReadyToGo ? (
                <ScheduleItem text={TEXT_STRING.SEARCH_PAGE.GO_NOW} />
              ) : startTime && !disabled ? (
                `${startTime}\n-\n${endTime || '\n'}`
              ) : (
                isToBeConfirmed && (
                  <ScheduleItem text={TEXT_STRING.SEARCH_PAGE.TO_BE_CONFIRM} />
                )
              )}
            </div>
            <div className={`py-2`}>
              {(disabled || !shiftArea?.name) && !hasSettingWorking ? (
                <Spacing />
              ) : hasSettingWorking ? (
                area?.name
              ) : (
                shiftArea?.name
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ListSchedule;
