import {
  Button,
  Chip,
  ChipMeeting,
  ConcatText,
  Icon,
  Loading,
  Header,
  Rating,
  Text,
  ChipRank,
  CollectionsImage,
  ProfileInfo,
  ProfileBiography,
  ListSchedule,
  PostList,
  Tabs,
  GiftPaymentStep,
} from 'components';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  EGiftPaymentStep,
  TBodyPushNotification,
  TCreateChannel,
  TUpsertFootPrint,
  TFemaleDetail,
  TPostDetail,
  TResPaymentGift,
} from 'types';
import { PAGE_SIZE, TEXT_STRING, queryKeys, routes } from '../../constants';
import {
  createChannel,
  createClientChannel,
  getFemaleDetail,
  getFemalePosts,
  pushNotificationMessage,
  updateChannelActive,
  upsertFootPrint,
} from 'api';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth, useFollow, useOrder, useToast } from 'hooks';
import { detectMobile, getFormattedAmount } from 'utils';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { Waypoint } from 'react-waypoint';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useUserStore } from 'store';
import LoadingPost from 'components/PostItem/LoadingPost';
import { usePubNub } from 'pubnub-react';

const DETAIL_STR = TEXT_STRING.CAST_PAGE;
const COMMON_STR = TEXT_STRING.COMMON;
const POST_STR = TEXT_STRING.POST;

enum EPostTab {
  ALL_POST = 'ALL_POST',
  PREMIUM_POST = 'PREMIUM_POST',
}

const listTab = [
  {
    title: POST_STR.ALL_POST,
    id: EPostTab.ALL_POST,
  },
  {
    title: POST_STR.PAID_POST,
    id: EPostTab.PREMIUM_POST,
  },
];

function DetailFemale() {
  // Hooks
  const { id } = useParams();
  const { checkNeedToLogin } = useAuth();
  const isMobile = detectMobile();
  const { handleFollow, loadingFollow } = useFollow();
  const navigate = useNavigate();
  const { isAuthenticated } = useUserStore();
  const { gotoCall } = useOrder();
  const { toastSuccess } = useToast();
  const { user } = useUserStore();
  const pubnub = usePubNub();

  // States
  const [following, setFollowing] = useState<boolean>(false);
  const [viewScroll, setViewScroll] = useState<boolean>(true);
  const [postTab, setPostTab] = useState<EPostTab>(EPostTab.ALL_POST);
  const [stepPayment, setStepPayment] = useState<EGiftPaymentStep>();
  const [premiumPostInfo, setPremiumPostInfo] = useState<TPostDetail>();

  // Ref
  const footPrintCalled = useRef(false);

  // Query
  const { data, isLoading, isFetching } = useQuery<TFemaleDetail>({
    queryKey: [queryKeys.DETAIL_FEMALE, id],
    queryFn: () => getFemaleDetail(+(id || '')),
    enabled: !!id,
  });

  const {
    data: postData,
    fetchNextPage,
    hasNextPage,
    isLoading: isLoadingPost,
  } = useInfiniteQuery({
    queryKey: [queryKeys.FEMALE_POST, id, postTab],
    queryFn: async ({ pageParam }) =>
      getFemalePosts({
        userId: +(id || 0),
        currentPage: pageParam,
        pageSize: PAGE_SIZE,
        isPremium: isPremiumPost,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, currentPage) => {
      return lastPage?.totalPage > currentPage ? currentPage + 1 : undefined;
    },
    enabled: !!id && postTab === EPostTab.ALL_POST,
  });

  const {
    data: premiumPostData,
    fetchNextPage: fetchNextPagePremiumPost,
    hasNextPage: hasNextPagePremiumPost,
    isLoading: isLoadingPremiumPost,
  } = useInfiniteQuery({
    queryKey: [queryKeys.FEMALE_POST, id, postTab],
    queryFn: async ({ pageParam }) =>
      getFemalePosts({
        userId: +(id || 0),
        currentPage: pageParam,
        pageSize: PAGE_SIZE,
        isPremium: isPremiumPost,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, currentPage) => {
      return lastPage?.totalPage > currentPage ? currentPage + 1 : undefined;
    },
    enabled: !!id && postTab === EPostTab.PREMIUM_POST,
  });

  // Mutation
  const { mutateAsync, isPending } = useMutation({
    mutationFn: (body: TCreateChannel) => {
      return isAuthenticated ? createChannel(body) : createClientChannel(body);
    },
  });

  const { mutate: mutatePushNotification } = useMutation({
    mutationFn: (body: TBodyPushNotification) => {
      return pushNotificationMessage(body);
    },
    retry: 0,
  });

  const { mutate: mutateChannelActive } = useMutation({
    mutationFn: ({ channelId }: { channelId: string }) => {
      return updateChannelActive(channelId);
    },
    retry: 0,
  });

  const { mutate: mutateUpsertFootPrint } = useMutation({
    mutationFn: (body: TUpsertFootPrint) => {
      return upsertFootPrint(body);
    },
    retry: 0,
  });

  // Memo, callbacks
  const [isPremiumPost] = useMemo(
    () => [postTab === EPostTab.PREMIUM_POST],
    [postTab]
  );

  const basicInfo = useMemo(() => {
    return [
      {
        label: DETAIL_STR.RANK,
        value: <ChipRank rank={data?.rank} className="w-40" />,
      },
      {
        label: DETAIL_STR.AGE_NUMBER,
        value: `${data?.age}${COMMON_STR.AGE}`,
      },
      {
        label: DETAIL_STR.HEIGHT,
        value: `${data?.height}${DETAIL_STR.CM}`,
      },
      {
        label: DETAIL_STR.NUMBER_CUP,
        value: data?.cupSize,
      },
      {
        label: DETAIL_STR.AFFILIATION_AREA,
        value: data?.activityArea?.name,
      },
    ];
  }, [data]);

  const onConfirmGift = useCallback((data: TPostDetail) => {
    setStepPayment(EGiftPaymentStep.CHOOSE_METHOD);
    setPremiumPostInfo(data);
  }, []);

  // TODO: send message when buy premium post
  const sendGiftMessage = useCallback(
    async (payload: TResPaymentGift) => {
      const {
        message,
        image: giftImage,
        animations: giftAnimations,
        name: giftName,
        channel,
      } = payload;
      let messageBody = `${message.trim()}\n`;
      if (giftName)
        messageBody += TEXT_STRING.COMMON.QUOTE.replace('$value', giftName);
      const notificationTitle = `${user?.name || ''} ${
        TEXT_STRING.COMMON.RECEIVED_MESSAGE
      }`;
      const gift = {
        image: giftImage,
        animations: giftAnimations,
        name: giftName,
      };

      const messagePayload = {
        text: message,
        pn_gcm: {
          notification: {
            title: notificationTitle,
            body: messageBody,
            sound: 'default',
          },
          data: {
            key: channel?.id,
            type: channel?.type,
          },
        },
        gift,
      };

      if (message || gift) {
        await pubnub.publish({
          channel: channel?.id,
          message: messagePayload,
        });
      }

      mutatePushNotification({
        channelId: channel?.id,
        channelType: channel?.type || '',
        message: messageBody,
        title: notificationTitle,
      });

      // send channel active lastMessage
      mutateChannelActive({ channelId: channel?.id });

      const channelUsers = channel?.members?.filter(
        (item) => item.uuid !== user?.uuid
      );

      if (channelUsers) {
        Promise.all(
          channelUsers.map(({ uuid }) =>
            pubnub.publish({
              channel: uuid,
              message: {
                channel: channel?.id,
                text: messageBody,
              },
            })
          )
        );
      }
    },
    [
      mutateChannelActive,
      mutatePushNotification,
      pubnub,
      user?.name,
      user?.uuid,
    ]
  );

  const formatValue = useCallback((value: number) => {
    return getFormattedAmount(value);
  }, []);

  const getDiscountInfo = useCallback(
    (label: string, amount = 0, applied = 0) => ({
      label: label,
      amount: formatValue(amount),
      applied: formatValue(applied),
      disabled: !amount || !applied,
    }),
    [formatValue]
  );

  const discountInfo = useMemo(
    () => [
      getDiscountInfo(
        COMMON_STR.DISCOUNT_FOR_MULTIPLE,
        data?.groupDiscount?.amount,
        data?.groupDiscount?.applied
      ),
      getDiscountInfo(
        COMMON_STR.DISCOUNT_FOR_ONE,
        data?.singleDiscount?.amount,
        data?.singleDiscount?.applied
      ),
    ],
    [
      data?.groupDiscount?.amount,
      data?.groupDiscount?.applied,
      data?.singleDiscount?.amount,
      data?.singleDiscount?.applied,
      getDiscountInfo,
    ]
  );

  const handleFollowing = useCallback(() => {
    if (!checkNeedToLogin()) {
      setFollowing(!following);
      handleFollow(data?.userId ?? 0, following);
    }
  }, [checkNeedToLogin, data?.userId, following, handleFollow]);

  const gotoConversation = useCallback(async () => {
    if (!data?.userId) return;
    try {
      let route = '';
      if (data?.channelId) {
        route = routes.CONVERSATION.replace(':id', data.channelId);
      } else {
        const channel = await mutateAsync({
          femaleId: data.userId,
        });
        route = routes.CONVERSATION.replace(':id', channel.id);
      }
      navigate(route);
    } catch (error) {
      toast.error((error as AxiosError).message);
    }
  }, [data, navigate, mutateAsync]);

  const isLoadingFollow = useMemo(
    () => loadingFollow?.includes(data?.userId ?? 0),
    [data?.userId, loadingFollow]
  );

  useEffect(() => {
    setFollowing(data?.isFollowed ?? false);
  }, [data?.isFollowed]);

  useEffect(() => {
    if (!user || !id || footPrintCalled.current) return;
    mutateUpsertFootPrint({ maleId: user.id, femaleId: id });
    footPrintCalled.current = true;
  }, [user, id, mutateUpsertFootPrint]);

  const postListView = useMemo(() => {
    const data = isPremiumPost ? premiumPostData : postData;
    return data?.pages.map(({ data }) => data).flat() ?? [];
  }, [isPremiumPost, premiumPostData, postData]);

  if (isLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="h-full">
      <Header
        title={
          <div className="flex items-center justify-center line-clamp-1 gap-4">
            <ConcatText
              prefixText={data?.nickname}
              suffixText={data?.userId}
              className="font-bold line-clamp-1"
            />
            <Rating value={+(data?.point || '')} />
          </div>
        }
      />
      {data ? (
        <div className="h-full overflow-hidden pb-16">
          <InfiniteScroll
            next={isPremiumPost ? fetchNextPagePremiumPost : fetchNextPage}
            hasMore={isPremiumPost ? hasNextPagePremiumPost : hasNextPage}
            loader={<LoadingPost />}
            dataLength={postListView.length}
            scrollThreshold={0.5}
            height={'calc(100dvh - 68px - 46px)'}
          >
            <div className="overflow-hidden">
              {/* Avatar, collections */}
              <CollectionsImage
                avatar={data?.avatar}
                collections={data?.collections}
              />
              {/* Profile */}
              <Waypoint
                onEnter={() => {
                  setViewScroll(false);
                }}
                onLeave={() => {
                  setViewScroll(true);
                }}
                bottomOffset={'20%'}
              >
                <div className="px-4 pb-28">
                  <div className="py-12px">
                    {/* active status */}
                    <div className="flex flex-wrap w-full justify-between gap-26px">
                      <div className="flex flex-col gap-10px">
                        <div className="flex items-center gap-4px">
                          <div
                            className={`w-12px h-12px rounded-full ${
                              !data?.schedules.some(
                                (schedule) => !schedule.disabled
                              ) &&
                              !data?.isReadyToGo &&
                              !data?.isToBeConfirmed
                                ? 'bg-neutral-400'
                                : 'bg-success-400'
                            } `}
                          ></div>
                          <Text bold fontSize={14}>
                            {DETAIL_STR.SCHEDULES}
                          </Text>
                        </div>
                        <ChipMeeting isMeeting={data?.isMeeting} />
                      </div>
                      {data?.isPeriodTime && (
                        <div className="flex items-center self-start gap-[7px]">
                          <img
                            src="/images/menstruation.png"
                            alt=""
                            className="w-5 h-5"
                          />
                          <Text textColor="text-error-400" fontSize={14}>
                            {DETAIL_STR.PERIOD_MESSAGE}
                          </Text>
                        </div>
                      )}
                      <Button
                        onClick={() => handleFollowing()}
                        bgColor={
                          isLoadingFollow
                            ? 'bg-error-200'
                            : following
                            ? ''
                            : 'bg-error-400'
                        }
                        textColor={
                          following
                            ? isLoadingFollow
                              ? 'text-white'
                              : 'text-error-400'
                            : 'text-white'
                        }
                        border={following ? 'border' : ''}
                        className={`self-start ${
                          !isLoadingFollow && 'border-error-400'
                        }  ${data?.isPeriodTime ? 'flex-1' : 'flex-[0.5]'} ${
                          isLoadingFollow && 'pointer-events-none'
                        } text-[14px]`}
                        bold
                      >
                        {following ? DETAIL_STR.FOLLOWING : DETAIL_STR.FOLLOW}
                      </Button>
                    </div>
                    {/* Profile schedules */}
                    <ListSchedule
                      schedules={data?.schedules}
                      area={data?.activityArea}
                      isToBeConfirmed={data?.isToBeConfirmed}
                      enableReadyToGo={data?.isReadyToGo}
                    />
                    {/* Profile detail */}
                    <div className="mt-[17px] flex flex-col gap-4 text-[14px]">
                      <ProfileInfo data={basicInfo} title={COMMON_STR.INFO} />
                      <Text
                        bold
                        className={`${
                          discountInfo.some((item) => !item.disabled)
                            ? ''
                            : 'hidden'
                        }`}
                      >
                        {COMMON_STR.DISCOUNT_COUPON}
                      </Text>
                      {discountInfo.map((item, index) => {
                        return (
                          !item.disabled && (
                            <Fragment key={index}>
                              <Text>{item.label}</Text>
                              <Text>
                                {COMMON_STR.GAME_FEE}
                                <b>
                                  {item.amount}
                                  {COMMON_STR.YEN}
                                </b>
                                {COMMON_STR.DISCOUNT_NOTE}
                                <b>
                                  {item.applied}
                                  {COMMON_STR.YEN}
                                </b>
                                {COMMON_STR.DISCOUNT}
                              </Text>
                            </Fragment>
                          )
                        );
                      })}
                      <ProfileBiography note={data?.biography} />
                    </div>
                    {/* Female tags */}
                    <div className="flex flex-wrap gap-8px mt-22px">
                      {data?.options?.map((option) => (
                        <Chip
                          key={option.id}
                          bgColor="bg-white"
                          textColor="text-neutral-600"
                          ringColor="ring-black"
                          className="text-[14px]"
                        >
                          {option?.label}
                        </Chip>
                      ))}
                    </div>
                  </div>
                  {/* List post */}
                  <>
                    <hr className="solid my-8" />
                    <Text textColor="text-primary" bold fontSize={14} center>
                      {COMMON_STR.RECENT_TWEETS}
                    </Text>
                    <Tabs
                      className="my-8"
                      currentTab={postTab}
                      tabs={listTab}
                      onChangeTab={(tab) => setPostTab(tab as EPostTab)}
                    />
                    {isLoadingPost || isLoadingPremiumPost ? (
                      <LoadingPost />
                    ) : postListView?.length ? (
                      <PostList
                        data={postListView}
                        onConfirmGift={onConfirmGift}
                      />
                    ) : (
                      <Text center bold fontSize={16} className="mt-4">
                        {TEXT_STRING.COMMON.NO_DATA}
                      </Text>
                    )}
                  </>
                </div>
              </Waypoint>
            </div>
          </InfiniteScroll>
          <div
            className={`sticky bottom-0 ${
              isMobile ? 'w-full' : 'w-[50vh]'
            } p-12px mx-auto z-[2]`}
          >
            {viewScroll && (
              <div className="absolute -top-9 bg-black/50 left-1/2 -translate-x-1/2 py-2 px-10 backdrop-blur-sm rounded-full">
                <Icon icon="chevronDownDouble" />
              </div>
            )}
            <div className="flex gap-4">
              <Button
                className="w-full py-16px rounded-[8px] text-[14px]"
                onClick={gotoConversation}
                disabled={(!data?.channelId && isFetching) || isPending}
                loading={isPending}
              >
                <Text
                  bold
                  center
                  textColor="text-white"
                  className="line-clamp-2 break-all"
                >
                  {DETAIL_STR.CHAT}
                </Text>
              </Button>
              <Button
                className="w-full py-16px rounded-[8px] text-[14px]"
                onClick={() => gotoCall(data?.userId)}
              >
                <Text
                  bold
                  center
                  textColor="text-white"
                  className="line-clamp-2 break-all"
                >
                  {DETAIL_STR.CALL}
                </Text>
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Text center bold fontSize={16} className="mt-4">
          {TEXT_STRING.COMMON.NO_DATA}
        </Text>
      )}
      <GiftPaymentStep
        step={stepPayment}
        onClose={() => {
          setStepPayment(undefined);
        }}
        giftId={+(premiumPostInfo?.gift?.id || 0)}
        femaleId={+(premiumPostInfo?.user?.id || 0)}
        postId={+(premiumPostInfo?.id || 0)}
        onNextStep={() => {}}
        onSendMessage={(res) => {
          sendGiftMessage({ ...res, message: '' });
        }}
        onChooseStep={(step) => setStepPayment(step)}
        onSuccess={() => {
          toastSuccess(TEXT_STRING.COMMON.SUCCESS);
          setStepPayment(undefined);
        }}
      />
    </div>
  );
}

export default DetailFemale;
