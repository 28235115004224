import axios from 'axios';
import { TUpsertFootPrint } from 'types';

const upsertFootPrint = async (body: TUpsertFootPrint) => {
  return await axios
    .post('foot-print', body)
    .then(function (response) {
      return response.data;
    });
};

export { upsertFootPrint };
