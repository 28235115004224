import { useInfiniteQuery } from '@tanstack/react-query';
import { getFemaleListDiscount } from 'api';
import {
  Avatar,
  Button,
  ConcatText,
  Icon,
  Loading,
  Rating,
  Text,
} from 'components';
import { useEffect, useState } from 'react';
import {
  PAGE_SIZE,
  queryKeys,
  routes,
  SESSION_KEYS,
  TEXT_STRING,
} from '../../../constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFollow } from 'hooks';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ESearchTab, TFemale } from 'types';
import { formatAmountNumber } from 'utils';
import { useAppStore } from 'store';

type TProps = {
  onFollow?: (female: TFemale) => void;
};

function ListDiscount({ onFollow }: TProps) {
  // State
  const [, setSearchParams] = useSearchParams();
  const [listView, setListView] = useState<{
    list: TFemale[];
    page: number;
  }>({
    list: [],
    page: 1,
  });

  // Hook
  const { loadingFollow } = useFollow();
  const navigate = useNavigate();
  const { filterFemale } = useAppStore();

  // Query
  const {
    data: listFemale,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [queryKeys.FEMALE_LIST_DISCOUNT, filterFemale],
    queryFn: async ({ pageParam }) => {
      return getFemaleListDiscount({
        currentPage: pageParam,
        pageSize: PAGE_SIZE,
        filter: filterFemale,
      });
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, currentPage) => {
      return lastPage?.totalPage > currentPage ? currentPage + 1 : undefined;
    },
  });

  useEffect(() => {
    if (listFemale?.pages?.length) {
      setListView((prev) => {
        const listData =
          prev.page >= listFemale?.pages.length || prev.page === 1
            ? JSON.parse(
                JSON.stringify(listFemale?.pages.map(({ data }) => data).flat())
              )
            : [...prev.list, ...(listFemale?.pages.slice(-1)[0].data ?? [])];

        return { list: listData, page: listFemale?.pages.length };
      });
    }
  }, [listFemale?.pages, setListView]);

  return (
    <div className="px-8px pt-4">
      {isLoading ? (
        <div className="mt-8">
          <Loading />
        </div>
      ) : listView?.list?.length ? (
        <>
          <Text bold className="text-[12px] 2xs:text-[14px] xs:text-[16px]">
            {TEXT_STRING.SEARCH_PAGE.LIST_DISCOUNT_INDIVIDUAL}
          </Text>
          <InfiniteScroll
            next={fetchNextPage}
            hasMore={hasNextPage}
            loader={<></>}
            dataLength={listView?.list?.length}
            scrollThreshold={0.5}
            scrollableTarget="scrollFemaleDiscount"
          >
            <div
              className="flex gap-8px py-4 overflow-x-auto"
              id="scrollFemaleDiscount"
            >
              {listView?.list?.map((female) => {
                const isLoadingFollow = loadingFollow.includes(female?.userId);
                return (
                  <div
                    key={female?.id}
                    onClick={() => {
                      navigate(
                        `${routes.DETAIL_FEMALE.replace(
                          ':id',
                          `${female.userId}`
                        )}`
                      );
                    }}
                    className="flex flex-col pb-2 cursor-pointer justify-center items-center w-[30%] bg-primary-50 border border-solid border-primary rounded-lg flex-shrink-0"
                  >
                    <div className="w-full relative">
                      <Avatar url={female?.avatar} className="rounded-t-lg" />
                      <div
                        className="absolute right-1 top-1 rounded-full w-20px h-20px flex justify-center items-center bg-black/[.15]"
                        onClick={async (e) => {
                          e.stopPropagation();
                          if (!isLoadingFollow && onFollow) {
                            onFollow(female);
                            const femaleWithUserId = listView.list.findIndex(
                              (obj) => obj.userId === female.userId
                            );
                            listView.list[femaleWithUserId].isFollowed =
                              !female.isFollowed;
                            setListView((prev) => ({
                              ...prev,
                              list: listView.list,
                            }));
                          }
                        }}
                      >
                        <Icon
                          icon={female?.isFollowed ? 'heartActive' : 'heart'}
                        />
                      </div>
                      <Rating
                        value={female.point}
                        className="justify-center pt-2"
                      />
                    </div>
                    <div className="flex items-center justify-center gap-2 w-full">
                      <ConcatText
                        hyphen=""
                        prefixText={female.nickname}
                        suffixText={female.userId}
                        className="font-bold max-w-[90%] text-xs mt-2"
                      />
                    </div>
                    <Text fontSize={14} bold textColor="text-primary">
                      {`${formatAmountNumber(female?.singleDiscount?.applied)}${
                        TEXT_STRING.COMMON.DISCOUNT
                      }`}
                    </Text>
                  </div>
                );
              })}
              {!hasNextPage && (
                <div className="flex flex-col pb-2 justify-center items-center w-[30%] bg-primary-50 border border-solid border-primary rounded-lg flex-shrink-0">
                  <Button
                    onClick={() => {
                      sessionStorage.setItem(
                        SESSION_KEYS.SEARCH_TAB,
                        ESearchTab.LIST_DISCOUNT
                      );
                      setSearchParams({
                        tab: ESearchTab.LIST_DISCOUNT,
                      });
                    }}
                    bold
                  >
                    {TEXT_STRING.COMMON.SHOW_ALL}
                  </Button>
                </div>
              )}
            </div>
          </InfiniteScroll>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ListDiscount;
