import { LIMIT_VALUE, TEXT_STRING } from '../constants';
import moment from 'moment';
import {
  EFieldFormTravel,
  EFilterFemale,
  EProfileInfo,
  EProjectType,
  ESettingNoti,
  TListItem,
} from 'types';

const MY_PAGE_STR = TEXT_STRING.MY_PAGE;

const initialValueCallWoman = {
  orderType: EProjectType.BASIC,
  setCount: '1',
  peopleCount: '1',
  location: '',
  areaId: '',
  date: moment().toString(),
  time: '00:00',
  maleCount: '1',
  meetingAddress: '',
  addressNumber: '',
  note: '',
};

const initialTravelValue = {
  [EFieldFormTravel.MALE_COUNT]: '1',
  [EFieldFormTravel.START_DATE]: moment().toString(),
  [EFieldFormTravel.END_DATE]: moment().add(1, 'day').toString(),
  [EFieldFormTravel.FEMALE_COUNT]: '1',
};

const initialFilterOptions = {
  [EFilterFemale.OPTIONS]: [],
  [EFilterFemale.RANKS]: [],
  [EFilterFemale.ACTIVITY_AREAS]: [],
  [EFilterFemale.STAR]: [],
};

const initialFilterFemale = {
  ...initialFilterOptions,
  [EFilterFemale.SCHEDULES]: {
    startTime: '',
    endTime: '',
  },
  [EFilterFemale.DISCOUNT]: {
    singleCoupon: false,
    groupCoupon: false,
  },
};

const FIELD_NAME = {
  MEETING_ADDRESS: 'meetingAddress',
  MALE_COUNT: 'maleCount',
  FEMALE_COUNT: 'femaleCount',
  NOTE: 'note',
  SET: 'setCount',
  PEOPLE: 'peopleCount',
  LOCATION: 'location',
  AREA_ID: 'areaId',
  SUB_AREA_ID: 'subAreaId',
  DATE: 'date',
  TIME: 'time',
  PLAY: 'play',
  GIRL: 'girl',
  RANK: 'rank',
  ADDRESS_NUMBER: 'addressNumber',
  ORDER_TYPE: 'orderType',
};

const profileField = [
  {
    name: EProfileInfo.AGE,
    type: 'text',
    label: TEXT_STRING.CAST_PAGE.AGE_NUMBER,
    unit: TEXT_STRING.COMMON.AGE,
    min: LIMIT_VALUE.MIN_NUMBER,
    max: LIMIT_VALUE.MAX_AGE,
  },
  {
    name: EProfileInfo.INCOME,
    type: 'text',
    label: TEXT_STRING.MALE_PAGE.ANNUAL_INCOME,
    unit: TEXT_STRING.COMMON.ONE_MAN,
    min: LIMIT_VALUE.MIN_NUMBER,
    max: LIMIT_VALUE.MAX_NUMBER,
  },
];

const settingItem: Record<ESettingNoti, TListItem> = {
  [ESettingNoti.GROUP_CHAT]: {
    icon: 'groupChat',
    label: MY_PAGE_STR.GROUP_CHAT_MESSAGE,
  },
  [ESettingNoti.STAFF_MESSAGE]: {
    icon: 'folder',
    label: MY_PAGE_STR.MESSAGE_FROM_STAFF,
  },
  [ESettingNoti.GIRL_MESSAGE]: {
    icon: 'chatLove',
    label: MY_PAGE_STR.TECO_GIRL_MESSAGE,
  },
  [ESettingNoti.CONFLUENCE]: {
    icon: 'userCheck',
    label: MY_PAGE_STR.CONFLUENCE,
  },
  [ESettingNoti.GAME_SELECTION]: {
    icon: 'game',
    label: MY_PAGE_STR.GAME_SELECTION,
  },
  [ESettingNoti.PROFILE_REQUEST]: {
    icon: 'profileCheck',
    label: MY_PAGE_STR.PROFILE_IMAGE_REVIEW,
  },
};

export {
  FIELD_NAME,
  initialValueCallWoman,
  initialFilterFemale,
  initialFilterOptions,
  profileField,
  settingItem,
  initialTravelValue,
};
