const acsTracking = (pId: string): void => {
  const PV = pId;
  const KEYS: Record<string, string[]> = {
    cid: ['CL_', 'ACT_', 'cid_auth_get_type'],
  };
  let turl = `https://s16.aspservice.jp/teco/track.php?p=${PV}`;

  // Cookieを解析してオブジェクト形式に変換
  const cks = document.cookie
    .split('; ')
    .reduce((ret: Record<string, string>, s) => {
      const kv = s.split('=');
      if (kv[0] && kv[1]) ret[kv[0]] = kv[1];
      return ret;
    }, {});

  // turlを更新
  turl = Object.keys(KEYS).reduce((url, k) => {
    const vk = KEYS[k][0] + PV; // クッキーのキー
    const tk = KEYS[k][1] + PV; // 認証タイプのキー
    let v = ''; // クッキーまたはローカルストレージの値
    let t = ''; // 認証タイプ

    if (cks[vk]) {
      v = cks[vk];
      if (cks[tk]) t = cks[tk];
    } else if (localStorage.getItem(vk)) {
      v = localStorage.getItem(vk) as string;
      t = 'ls';
    }

    if (v) url += `&${k}=${v}`;
    if (t) url += `&${KEYS[k][2]}=${t}`;
    return url;
  }, turl);

  // リクエスト送信
  const xhr = new XMLHttpRequest();
  xhr.open('GET', turl);
  xhr.send();
};

export default acsTracking;
