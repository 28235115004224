import Text from 'components/Text';
import { EXCEED_FRANCHISE_GAME, TEXT_STRING } from '../../../../../constants';
import { useCallback, useMemo } from 'react';
import {
  EPaymentMethod,
  TChannelProjectDetail,
  TProjectEstimatePrice,
  TTotalEstimatePrice,
} from 'types';
import Button from 'components/Button';
import Loading from 'components/Loading';
import { getFormattedAmount, validateGamePlayManyTime } from 'utils';

const STRINGS = TEXT_STRING.RELEASE_GIRL;

type Props = {
  loading?: boolean;
  loadingRelease?: boolean;
  data?: TProjectEstimatePrice;
  onSubmit: () => void;
  projectData: TChannelProjectDetail;
};

const Confirm = ({
  loading,
  loadingRelease,
  data,
  onSubmit,
  projectData,
}: Props) => {
  const total: {
    label: string;
    subLabel?: string;
    key: keyof TTotalEstimatePrice;
    note?: string;
    bold: boolean;
    hideForFC?: boolean;
    showField?: boolean;
  }[] = useMemo(
    () => [
      {
        label: STRINGS.TOTAL_ABOVE,
        key: 'total',
        bold: false,
        showField: true,
      },
      {
        label: STRINGS.PRE_PAID,
        key: 'prepaid',
        bold: false,
        note: STRINGS.HANDLING_FEE,
        showField: (data?.payment?.prepaid || 0) > 0,
      },
      {
        label: STRINGS.PROJECT_DISCOUNT,
        key: 'discount',
        bold: false,
        showField: (data?.payment?.discount || 0) > 0,
      },
      {
        label: STRINGS.TOTAL_CASH,
        key: 'totalAfterDiscount',
        bold: true,
        hideForFC: true,
        showField: data?.allowMethod?.includes(EPaymentMethod.CASH),
      },
      {
        label: STRINGS.TOTAL_CARD,
        key: 'totalAfterDiscountCardPayment',
        note: STRINGS.HANDLING_FEE,
        bold: true,
        hideForFC: true,
        showField: true,
      },
    ],
    [data?.allowMethod, data?.payment?.discount, data?.payment?.prepaid]
  );

  const formatPrice = useCallback((value = 0) => {
    return `${getFormattedAmount(value)}${TEXT_STRING.COMMON.YEN}`;
  }, []);

  return (
    <div>
      <Text className="mb-2">{STRINGS.PAYMENT_CONFIRM}</Text>
      {loading ? (
        <div className="py-16">
          <Loading />
        </div>
      ) : (
        <div className="">
          {data?.females.map((female) => {
            return (
              <div key={female.femaleId} className="my-4">
                <Text bold>{female.nickname}</Text>
                <Text className="py-1">
                  {`${TEXT_STRING.RELEASE_GIRL.TOTAL_PRICE}: ${formatPrice(
                    female.total
                  )}`}
                  <b>{`（${female.setCount}${TEXT_STRING.COMMON.SET}）`}</b>
                </Text>
                {female.games.map((game) => {
                  return (
                    <Text className="py-1" key={game.id}>
                      {`${game.name}: ${
                        validateGamePlayManyTime(game.type)
                          ? game.orderCount
                          : ''
                      }${game.unit} ${formatPrice(game.total)}`}
                    </Text>
                  );
                })}
                {!!female.nominatedPrice && (
                  <Text className="py-1">{`${
                    STRINGS.NOMINATION_FEE
                  }: ${formatPrice(female.nominatedPrice)}`}</Text>
                )}
                {!!female.totalDiscount && (
                  <Text className="py-1">
                    {`${STRINGS.GAME_DISCOUNT}: ${formatPrice(
                      female.totalDiscount
                    )}`}
                  </Text>
                )}
                {female.isExceedFranchiseGames && (
                  <Text bold>
                    {STRINGS.GAME_PAYMENT_NOTICE.replaceAll(
                      '$price',
                      (projectData.isSingleMeeting
                        ? EXCEED_FRANCHISE_GAME.SINGLE
                        : EXCEED_FRANCHISE_GAME.GROUP
                      ).toString()
                    )}
                  </Text>
                )}
              </div>
            );
          })}
          <Text className="mt-4" bold>
            {STRINGS.TOTAL}
          </Text>
          {total.map(({ label, key, note, bold, hideForFC, showField }) => {
            return !(hideForFC && projectData.isFranchiseProject) &&
              showField ? (
              <Text key={key} className="py-1" bold={bold}>
                {label}: {formatPrice(data?.payment[key])}
                {note}
              </Text>
            ) : null;
          })}
          {projectData.isFranchiseProject &&
            data?.payment.total !== data?.payment.totalAfterDiscount && (
              <Text className="py-1" bold>
                {STRINGS.TOTAL}: {formatPrice(data?.payment.totalAfterDiscount)}
              </Text>
            )}
        </div>
      )}
      <div className="py-4">
        <Button
          onClick={onSubmit}
          block
          disabled={loading || loadingRelease}
          loading={loadingRelease}
        >
          {TEXT_STRING.COMMON.CONTINUE}
        </Button>
      </div>
    </div>
  );
};

export default Confirm;
