import {
  CSSProperties,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Loading from 'components/Loading';
import Text from 'components/Text';
import {
  REFETCH_UNREAD_COUNT_TIMEOUT,
  SIGNAL_EVENT,
  TEXT_STRING,
} from '../../constants';
import { EChannelType, TChannel, TMessageDetail } from 'types';
import ChannelListItem from './ChannelListItem';
import { MessageEvent, SignalEvent } from 'pubnub';
import { usePubNub } from 'pubnub-react';
import { useUserStore } from 'store';
import AvatarChatGroup from 'components/AvatarGroup/AvatarChatGroup';
import VirtualizedList from 'components/VirtualizedList';
import moment from 'moment';

type TLastMessageList = {
  [x: string]: TMessageDetail;
};

type Props = {
  isLoading?: boolean;
  channels: TChannel[];
  fetchMore: () => void;
  refetch: () => void;
  hasMore: boolean;
  createChannelWithAdmin: (isAuto: boolean) => void;
};

const ChannelList = ({
  isLoading,
  channels,
  fetchMore,
  hasMore,
  refetch,
  createChannelWithAdmin,
}: Props) => {
  // Hooks
  const pubnub = usePubNub();
  const { isAuthenticated, user, clientId } = useUserStore();
  const timeoutRef = useRef<NodeJS.Timeout>();

  // States
  const [lastMessageList, setLastMessageList] = useState<TLastMessageList>({});
  const [unreadMessageList, setUnreadMessageList] = useState<{
    [x: string]: number;
  }>({});
  const [pinnedChannel, setPinnedChannel] = useState<Record<string, string>>(
    {}
  );

  // Memo, callbacks
  const mapMessage = useCallback(
    (message: TMessageDetail) => {
      let url;
      if (message.message.file) {
        url = pubnub.getFileUrl({
          channel: message.channel,
          id: message.message.file.id,
          name: message.message.file.name,
        });
      }
      return { ...message, url: url };
    },
    [pubnub]
  );

  const updateUnread = useCallback(
    (channelId: string) => {
      setUnreadMessageList((prev) => ({
        ...prev,
        [channelId]:
          (prev[channelId] ||
            channels.find((channel) => channel.id === channelId)?.unreadCount ||
            0) + 1,
      }));
    },
    [channels]
  );

  const refetchChannels = useCallback(
    (channelId: string) => {
      if (!channels.some((channel) => channel.id === channelId)) {
        if (!timeoutRef.current) {
          timeoutRef.current = setTimeout(() => {
            refetch();
            setLastMessageList([]);
            setUnreadMessageList({});
            clearTimeout(timeoutRef.current);
            timeoutRef.current = undefined;
          }, REFETCH_UNREAD_COUNT_TIMEOUT);
        }
        return true;
      }
      return false;
    },
    [channels, refetch]
  );

  const refetchLastMessages = useCallback(() => {
    pubnub
      .fetchMessages({
        channels: channels.map(({ id }) => id),
        count: 1,
      })
      .then((res) => {
        const newState = Object.keys(res.channels).reduce<TLastMessageList>(
          (acc, cur) => {
            acc[cur] = res.channels[cur][0];
            return acc;
          },
          {}
        );
        setLastMessageList(newState);
      })
      .catch((err) => console.error(err));
  }, [pubnub, channels]);

  const handleMessage = useCallback(
    (event: MessageEvent) => {
      const channelId = event.message.channel;
      if (!channelId) return;
      if (!refetchChannels(channelId)) {
        setLastMessageList((prev) => ({
          ...prev,
          [channelId]: mapMessage({
            channel: channelId,
            message: event.message,
            timetoken: event.timetoken,
            uuid: event.publisher,
          }),
        }));
        updateUnread(channelId);
      }
    },
    [mapMessage, updateUnread, refetchChannels]
  );

  const handleSignal = useCallback(
    (params: SignalEvent) => {
      const message = params.message;
      switch (message.event || message) {
        case SIGNAL_EVENT.DELETE_MESSAGE:
          refetchLastMessages();
          break;

        default:
          break;
      }
    },
    [refetchLastMessages]
  );

  const getUnreadCount = useCallback(
    (item: TChannel) =>
      (lastMessageList[item.id] || item.lastMessage)?.uuid !==
      (user?.uuid || clientId)
        ? unreadMessageList[item.id] ?? item.unreadCount
        : 0,
    [clientId, lastMessageList, unreadMessageList, user?.uuid]
  );

  const handlePinChannel = useCallback(
    (channelId: string, pinOrder: string) => {
      setPinnedChannel((prev) => ({ ...prev, [channelId]: pinOrder }));
    },
    []
  );

  const channelsView = useMemo(() => {
    const isManagementChannel = (channel: TChannel) =>
      [EChannelType.MALE_MANAGEMENT].includes(channel.type);

    return channels
      .map((channel) => ({
        ...channel,
        lastMessage: lastMessageList[channel.id] || channel.lastMessage,
        isManagement: isManagementChannel(channel),
        pinOrder: pinnedChannel[channel.id] ?? channel.pinOrder,
      }))
      .sort((a, b) => {
        if (a.pinOrder && !b.pinOrder) {
          return -1;
        } else if (!a.pinOrder && b.pinOrder) {
          return 1;
        } else if (a.pinOrder && b.pinOrder) {
          return moment(b.pinOrder).valueOf() - moment(a.pinOrder).valueOf();
        } else {
          return b?.lastMessage?.timetoken - a?.lastMessage?.timetoken;
        }
      });
  }, [channels, lastMessageList, pinnedChannel]);

  // If the user is not logged in and has not chatted with the administrator before
  // Display the chat channel to contact the administrator
  const showAdminChannel = useMemo(
    () =>
      !isAuthenticated &&
      !channelsView.some(({ isManagement }) => isManagement),
    [isAuthenticated, channelsView]
  );

  const showSystemChannel = useMemo(
    () =>
      !isAuthenticated &&
      !channelsView.some(({ type }) =>
        [EChannelType.MALE_SYSTEM_MANAGEMENT].includes(type)
      ),
    [isAuthenticated, channelsView]
  );

  const rowCount = useMemo(
    () => channels.length + +hasMore + +showAdminChannel + +showSystemChannel,
    [channels.length, hasMore, showAdminChannel, showSystemChannel]
  );

  // Effects
  useEffect(() => {
    const listenerParams = {
      message: handleMessage,
      signal: handleSignal,
    };
    pubnub.addListener(listenerParams);

    return () => {
      pubnub.removeListener(listenerParams);
    };
  }, [pubnub, handleMessage, handleSignal]);

  return (
    <>
      {isLoading ? (
        <div className="my-[80%]">
          <Loading />
        </div>
      ) : isAuthenticated && !channels.length ? (
        <Text className="my-[80%] tracking-widest" center bold>
          {TEXT_STRING.MESSAGE.START_MESSAGE}
        </Text>
      ) : (
        <div className="flex-1">
          <VirtualizedList
            rowCount={rowCount}
            hasMore={hasMore}
            fetchMore={fetchMore}
            dataLength={channels.length}
            RowItem={({ index, measure, registerChild, style }) => {
              // Displays the chat channel to contact the administrator at the top
              const item = channelsView[index - +showAdminChannel];

              if (index === 0 && showAdminChannel) {
                return (
                  <FakeChannelItem
                    ref={registerChild}
                    style={style}
                    onClick={() => createChannelWithAdmin(false)}
                    title={TEXT_STRING.COMMON.MGM_STAFF}
                    isHighlight
                  />
                );
              }

              if (rowCount === index + 1 && showSystemChannel) {
                return (
                  <FakeChannelItem
                    ref={registerChild}
                    style={style}
                    onClick={() => createChannelWithAdmin(true)}
                    title={TEXT_STRING.MESSAGE.AUTOMATED_CHAT}
                    message={TEXT_STRING.MESSAGE.FAKE_PREVIEW}
                  />
                );
              }

              return (
                <div ref={registerChild} style={style}>
                  {item ? (
                    <ChannelListItem
                      channel={item}
                      unreadCount={getUnreadCount(item)}
                      onPinChannel={handlePinChannel}
                    />
                  ) : (
                    <ItemLoading index={index} />
                  )}
                </div>
              );
            }}
          />
        </div>
      )}
    </>
  );
};

const FakeChannelItem = forwardRef(
  (
    {
      onClick,
      title,
      message,
      style,
      isHighlight,
    }: {
      onClick: () => void;
      title: string;
      message?: string;
      style: CSSProperties;
      isHighlight?: boolean;
    },
    ref: any
  ) => {
    return (
      <div
        ref={ref}
        style={style}
        className={`flex w-full gap-5 px-4 py-2 cursor-pointer ${
          isHighlight ? ' bg-primary-50 border-primary-200' : ''
        }`}
        onClick={onClick}
      >
        <AvatarChatGroup
          avatars={[{ url: '/images/logo192.png' }]}
          size="w-[4.5rem]"
          border={isHighlight ? 'border-2 border-primary' : ''}
        />
        <div className="flex-1 self-center">
          <div className="flex">
            <Text bold className="line-clamp-1 break-all flex-1 text-primary">
              {title}
            </Text>
          </div>

          {message && (
            <div className="flex mt-2">
              <div className="flex-1 line-clamp-1 break-all text-neutral-600">
                <Text className="line-clamp-1 break-all">{message}</Text>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

const ItemLoading = ({ index }: { index: number }) => {
  const animation =
    index % 2 === 0 ? `animate-pulse` : `animate-pulse [animation-delay:-0.1s]`;
  return (
    <div className={`flex w-full px-4 py-2`}>
      <div className={`h-16 w-16 rounded-full bg-gray-300 mr-3 ${animation}`} />
      <div
        className={`flex-1 rounded-xl border border-neutral-200 bg-gray-300 text-gray-300 ${animation}`}
      >
        ...Loading
      </div>
    </div>
  );
};

export default ChannelList;
