import { useCallback, useMemo, useState } from 'react';
import { TEXT_STRING } from '../../constants';
import TextField from 'components/TextField';
import Visa from '../../assets/images/card/visa.svg';
import MasterCard from '../../assets/images/card/mastercard.svg';
import JCB from '../../assets/images/card/jcb.svg';
import AMEX from '../../assets/images/card/amex.svg';
import { TTextFieldChangeEvent, TVeilpayPayload } from 'types';
import Button from 'components/Button';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { createVeilpayCustomerId } from 'api';

type Props = {
  submitAction?: {
    label?: string;
    action: (token?: string, customerId?: string) => Promise<void>;
    loading: boolean;
    disabled: boolean;
  };
  isVeilPay?: boolean;
  onCancel?: () => void;
};

type TFormState = {
  name: string;
  number: string;
  expDate: string;
  cvc: string;
};

const initialValues: TFormState = {
  name: '',
  number: '',
  expDate: '',
  cvc: '',
};

const STRs = TEXT_STRING.PAYMENT;

const fields: {
  name: keyof TFormState;
  label: string;
  placeholder?: string;
  validate?: (value: string) => boolean;
  type: string;
}[] = [
  {
    name: 'name',
    label: STRs.CARD_NAME,
    type: 'text',
  },
  {
    name: 'number',
    label: STRs.CARD_NUMBER,
    validate: (value: string) => !value || !!value.trim(),
    type: 'tel',
  },
  {
    name: 'expDate',
    label: STRs.EXPIRATION_DATE,
    placeholder: 'MM/YY',
    type: 'tel',
  },
  {
    name: 'cvc',
    label: STRs.SECURITY_CODE,
    type: 'tel',
  },
];

const PaymentForm = ({ submitAction, isVeilPay = false, onCancel }: Props) => {
  // State
  const [formState, setFormState] = useState<TFormState>(initialValues);
  const [loading, setLoading] = useState<boolean>(false);

  // Mutation
  const { mutateAsync, isPending } = useMutation({
    mutationFn: (body: TVeilpayPayload) => {
      return createVeilpayCustomerId(body);
    },
  });

  // Memo, Callback
  const isLoading = useMemo(
    () => loading || submitAction?.loading || isPending,
    [isPending, loading, submitAction?.loading]
  );

  const icons = useMemo(
    () => [
      { src: Visa, show: isVeilPay },
      { src: MasterCard, show: isVeilPay },
      { src: JCB, show: !isVeilPay },
      { src: AMEX, bg: 'bg-[#1F72CD]', show: !isVeilPay },
    ],
    [isVeilPay]
  );

  const onChangeField = useCallback((event: TTextFieldChangeEvent) => {
    const { name, value } = event.target;
    let formattedValue = value;
    if (name === 'expDate') {
      formattedValue = formattedValue.replace(/\D/g, '');
      if (formattedValue.length >= 3) {
        formattedValue =
          formattedValue.substring(0, 2) + '/' + formattedValue.substring(2, 4);
      }
    } else if (name === 'number') {
      formattedValue = formattedValue
        .replace(/\s+/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim();
    }
    setFormState((prev: TFormState) => ({
      ...prev,
      [name]: formattedValue,
    }));
  }, []);

  const onBlurField = useCallback((event: TTextFieldChangeEvent) => {
    const { name, value } = event.target;
    let formattedValue = value;
    if (name === 'number') {
      formattedValue = formattedValue
        .replace(/\s+/g, '')
        .replace(/(.{4})/g, '$1 ');
    }
    setFormState((prev) => ({
      ...prev,
      [name]: formattedValue,
    }));
  }, []);

  const onFocusField = useCallback((event: TTextFieldChangeEvent) => {
    const { name, value } = event.target;
    let convertValue = value;
    if (name === 'number') {
      convertValue = convertValue.replace(/\s+/g, '');
    }
    setFormState((prev) => ({
      ...prev,
      [name]: convertValue,
    }));
  }, []);

  const createCardToken = (cardInfo: any) => {
    return new Promise((resolve: (value: string) => void, reject) => {
      //@ts-ignore
      window.TokenPayment.createCardToken(cardInfo, (err, result) => {
        if (err) reject(err);
        if (result?.result_code !== '0000') {
          reject(new Error(result.messages[0]));
          return;
        }

        resolve(result?.cc_token);
      });
    });
  };

  const onSubmit = async () => {
    try {
      let token;
      let customerId;
      setLoading(true);
      const [month, year] = moment(formState.expDate, 'MM/YY')
        .format('MM/YYYY')
        .split('/');

      const cardInfo = {
        number: formState.number.replace(/\s+/g, ''),
        exp_month: month,
        exp_year: year,
        name: formState.name,
        cvc: formState.cvc,
      };

      const cardInfoVeilpay = {
        ...cardInfo,
        exp_month: +month,
        exp_year: +year?.slice(-2),
        cvc: +formState.cvc,
        email: '',
      };

      if (isVeilPay) {
        const data = await mutateAsync(cardInfoVeilpay);
        customerId = data?.customer_id;
      } else {
        token = await createCardToken(cardInfo);
      }
      if (submitAction) {
        await submitAction.action(token, customerId);
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-6 pb-4">
        <div className="flex justify-center gap-4">
          {icons.map(
            (icon, index) =>
              icon.show && (
                <div
                  key={index}
                  className={`flex border w-[60px] h-[40px] rounded-md ${icon.bg}`}
                >
                  <img className={`m-auto`} src={icon.src} alt="" />
                </div>
              )
          )}
        </div>
        {fields.map((field, index) => {
          return (
            <TextField
              key={index}
              label={field.label}
              name={field.name}
              onChange={onChangeField}
              value={formState[field.name]}
              placeholder={field.placeholder}
              className="mt-4"
              inputClass="py-3"
              type={field.type}
              onBlur={onBlurField}
              onFocus={onFocusField}
            />
          );
        })}
        <Button
          onClick={onSubmit}
          block
          loading={isLoading}
          disabled={isLoading}
        >
          {submitAction?.label || TEXT_STRING.COMMON.CONTINUE}
        </Button>
      </div>
      {onCancel && (
        <Button
          onClick={onCancel}
          bgColor="bg-transparent"
          textColor="text-primary"
          block
          padding="py-0"
          disabled={isLoading}
        >
          {TEXT_STRING.COMMON.CANCEL}
        </Button>
      )}
    </>
  );
};

export default PaymentForm;
