import { ChipMeeting, ConcatText, Icon, Rating, Text } from 'components';
import {
  RANK_STYLES,
  START_DAY_WORK,
  TEXT_STRING,
  TIME_FORMAT,
  TIMEZONE,
} from '../../constants';
import { TDiscount, TFemale } from 'types';
import { useCallback, useMemo } from 'react';
import { formatAmountNumber } from 'utils';
import { useAppStore } from 'store';
import NoAvatar from '../../assets/images/avatar.svg';
import moment from 'moment-timezone';

type CardItemProps = {
  onFollow: () => void;
  onDetail: () => void;
  data: TFemale;
  disabledFollow?: boolean;
  discount?: TDiscount;
  showMeeting?: boolean;
  showShiftTime?: boolean;
  startDate?: string;
};

const searchStr = TEXT_STRING.SEARCH_PAGE;

function CardItem({
  onFollow,
  onDetail,
  data,
  disabledFollow = false,
  showMeeting = false,
  showShiftTime = false,
  discount,
  startDate,
}: CardItemProps) {
  const { filterOptions } = useAppStore();

  const styles = useMemo(
    () => (data.rank ? RANK_STYLES[data.rank?.id] : RANK_STYLES[1]),
    [data.rank]
  );

  const concatPrice = useCallback((price: number, text: string) => {
    return `${formatAmountNumber(price)}${text}`;
  }, []);

  const shiftData = useMemo(() => data.shifts?.[0], [data.shifts]);
  const ItemInfo = ({ center = true }: { center?: boolean }) => {
    const shiftArea = filterOptions?.activityAreas.find(
      (item) => item.id === shiftData?.areaId
    );

    const isShowGo = () => {
      const currentTime = moment().tz(TIMEZONE);
      const selectedDate = moment(startDate).tz(TIMEZONE).format('YYYY-MM-DD');

      if (
        !data.isReadyToGo ||
        selectedDate !== currentTime.format('YYYY-MM-DD')
      )
        return false;

      if (!shiftData) return data.isReadyToGo;

      const startShift = moment.tz(
        `${selectedDate} ${shiftData.startTime}`,
        'YYYY-MM-DD HH:mm',
        TIMEZONE
      );

      if (
        startShift.isBefore(moment.tz(START_DAY_WORK, TIME_FORMAT, TIMEZONE))
      ) {
        startShift.add(1, 'day');
      }

      return currentTime.isBefore(startShift);
    };

    const showReadyToGo = isShowGo();

    return (
      <>
        <Rating value={data.point} />
        <div
          className={`flex items-center ${
            center && 'justify-center'
          } gap-2 w-full`}
        >
          <ConcatText
            hyphen=""
            prefixText={data.nickname}
            suffixText={data.userId}
            className={`font-bold ${
              data.isPeriodTime ? 'max-w-[80%]' : 'max-w-full'
            } text-[14px]`}
          />
          {data?.isPeriodTime && (
            <img src="/images/menstruation.png" alt="" className="w-5 h-5" />
          )}
        </div>
        {showMeeting && <ChipMeeting isMeeting={data.isMeeting} />}
        {showShiftTime ? (
          <>
            <Text className="line-clamp-1 text-sm" bold>
              {showReadyToGo || !shiftData ? data?.area?.name : shiftArea?.name}{' '}
              {searchStr.ATTENDANCE_AT_WORK}
            </Text>
            <Text className={`line-clamp-1`}>
              {showReadyToGo
                ? searchStr.GO_NOW
                : shiftData
                ? `${shiftData?.startTime} - ${shiftData?.endTime}`
                : searchStr.TO_BE_CONFIRM}
            </Text>
          </>
        ) : (
          <Text className="line-clamp-1">{`${data.height || ''}cm(${
            data.cupSize
          }${TEXT_STRING.CAST_PAGE.CUP})`}</Text>
        )}
      </>
    );
  };

  return (
    <div className={`${discount ? 'grid grid-cols-3' : 'flex flex-col'} gap-5`}>
      <div
        onClick={onDetail}
        className="col-span-1 flex flex-col gap-2 cursor-pointer justify-center items-center w-full"
      >
        <div className="w-full relative">
          <img
            src={data.avatar}
            alt=""
            className="w-full aspect-[0.95] object-cover object-top rounded-t-lg"
            onError={(e) => (e.currentTarget.src = NoAvatar)}
          />
          <div
            className="absolute right-1 top-1 rounded-full w-20px h-20px flex justify-center items-center bg-black/[.15]"
            onClick={async (e) => {
              e.stopPropagation();
              if (!disabledFollow) {
                onFollow();
              }
            }}
          >
            <Icon icon={data.isFollowed ? 'heartActive' : 'heart'} />
          </div>
          <div
            className="p-1 relative bg-cover"
            style={{
              backgroundImage: `url(${styles.bg})`,
            }}
          >
            <Text textColor={styles.color} center bold>
              {data.rank?.rank}
            </Text>
          </div>
        </div>
        {!discount && <ItemInfo />}
      </div>
      {discount && (
        <div className="col-span-2 gap-2 flex flex-col justify-between">
          <div className="flex flex-col gap-2">
            <ItemInfo center={false} />
          </div>
          <div
            className="bg-primary-50 border border-solid border-primary-300 p-4 rounded-lg flex flex-col gap-2 justify-end"
            onClick={onDetail}
          >
            <Text>
              {concatPrice(discount.amount, searchStr.DISCOUNT_MINIMUM)}
            </Text>
            <Text fontSize={20} bold textColor="text-primary">
              {concatPrice(discount.applied, TEXT_STRING.COMMON.DISCOUNT)}
            </Text>
          </div>
        </div>
      )}
    </div>
  );
}

export default CardItem;
