import React, { memo, useContext, useMemo } from 'react';
import { TMediaDetail } from 'types';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Modal from 'components/Modal';
import MediaSlide from 'components/MediaSlide';
import IconButton from 'components/IconButton';
import { PostContext } from 'contexts';
import GalleryGrid from '../GalleryGrid';
import Text from 'components/Text';
import Icon from 'components/Icon';
import { TEXT_STRING } from '../../../constants';
import Button from 'components/Button';
import { useAuth } from 'hooks';
import { useUserStore } from 'store';

type Props = {
  id: string;
  data: TMediaDetail[];
  onLoad?: () => void;
  isLockedPost?: boolean;
  isPremiumPost?: boolean;
  onPaidPost?: () => void;
};

function PostGallery({
  id,
  data,
  onLoad,
  isLockedPost = false,
  isPremiumPost = false,
  onPaidPost,
}: Props) {
  // Hooks
  const { galleryWidth } = useContext(PostContext);
  const { checkNeedToLogin } = useAuth();
  const { user } = useUserStore();

  // States
  const [selectedId, setSelectedId] = React.useState<string | undefined>();

  // Memo, callbacks
  const [gridHeight, minHeight] = useMemo(() => {
    let maxRatio = 0;
    data.forEach((item) => {
      if (item.originalHeight && item.originalWidth) {
        maxRatio = Math.max(item.originalHeight / item.originalWidth, maxRatio);
      }
    });
    return [
      (maxRatio * (galleryWidth || 0)) / (data.length === 2 ? 2 : 1),
      galleryWidth ? galleryWidth / 1.8 : undefined,
    ];
  }, [data, galleryWidth]);

  return (
    <>
      <div
        className="mt-8 relative"
        style={{
          height: !gridHeight ? undefined : gridHeight,
          minHeight: !minHeight ? undefined : minHeight,
        }}
      >
        <GridLoading />
        {isLockedPost && (
          <div className="absolute z-[1] flex flex-col gap-2 justify-center items-center w-full h-full">
            <Icon icon={'lock'} />
            <Text textColor="text-white" fontSize={14} className="w-1/2" center>
              {TEXT_STRING.POST.PAID_POST_NOTE}
            </Text>
          </div>
        )}
        <GalleryGrid
          media={data}
          onClickMedia={setSelectedId}
          id={id}
          onLoad={onLoad}
          isLockedPost={isLockedPost}
          isPremiumPost={isPremiumPost}
        />
        <Modal
          open={selectedId !== undefined}
          onClose={() => {
            setSelectedId(undefined);
          }}
        >
          {selectedId && (
            <div
              className="h-screen w-full flex items-center overflow-hidden"
              onClick={() => {
                setSelectedId(undefined);
              }}
            >
              <IconButton
                icon="close"
                transparent
                className="fixed top-0 left-0 z-50 m-4"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedId(undefined);
                }}
                iconColor="white"
              />
              <MediaSlide
                data={data}
                selectedItem={data.findIndex(({ id }) => id === selectedId)}
              />
            </div>
          )}
        </Modal>
      </div>
      {isLockedPost && (
        <Button
          onClick={() => {
            if (!checkNeedToLogin()) {
              onPaidPost && onPaidPost();
            }
          }}
          className="mt-4"
          block
          disabled={user?.isLimited}
        >
          {TEXT_STRING.POST.BROWSE}
        </Button>
      )}
    </>
  );
}

function GridLoading() {
  return (
    <div
      className={`h-full w-full absolute top-0 left-0 z-[-1] rounded-2xl border border-neutral-200 
        background-gradient bg-gradient-to-br from-neutral-200 via-white to-neutral-200`}
    ></div>
  );
}

export default memo(PostGallery);
