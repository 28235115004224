import { useEffect } from 'react';

type TrackingData = { [key: string]: string | undefined };

window.acs_cbs = window.acs_cbs || [];

const COOKIE_EXPIRY = `; expires=${new Date(
  new Date().getTime() + 63072000000
).toUTCString()}; path=/; SameSite=None; Secure`;

const parseQueryParams = (): TrackingData => {
  const query = window.location.search.substring(1);
  if (!query) return {};
  return query.split('&').reduce((acc: TrackingData, param) => {
    const [key, value] = param.split('=');
    if (value) {
      acc[key] = decodeURIComponent(value);
    }
    return acc;
  }, {});
};

const useAcsKeep = (): void => {
  useEffect(() => {
    const PK = 'p';
    const IMK = 'im';

    const LKEYS: Record<string, string[]> = { cid: ['cid', 'CL_', 'ACT_'] };
    const DKEYS: string[] = [];
    const PDIR = './';
    const durlBase = 'https://s16.aspservice.jp/teco/direct.php';

    const saveCookies = (data: TrackingData): void => {
      const p = data[PK];
      if (!p) return;

      const out: TrackingData = Object.keys(LKEYS).reduce(
        (acc: TrackingData, key) => {
          if (data[key]) acc[key] = data[key];
          return acc;
        },
        {}
      );

      if (Object.keys(out).length === 0) return;

      let purl = `${PDIR}lptag.php?p=${p}`;
      Object.keys(out).forEach((key) => {
        purl += `&${LKEYS[key][0]}=${out[key]}`;
        localStorage.setItem(`${LKEYS[key][1]}${p}`, out[key] as string);
      });

      const xhr = new XMLHttpRequest();
      xhr.open('GET', purl);

      xhr.onloadend = () => {
        if (xhr.status === 200 && xhr.response === '') {
          window.acs_cbs.forEach((cb: () => void) => cb());
          return;
        }

        Object.keys(out).forEach((key) => {
          document.cookie = `${LKEYS[key][1]}${p}=${decodeURIComponent(
            out[key] as string
          )}${COOKIE_EXPIRY}`;
          if (LKEYS[key][2]) {
            document.cookie = `${LKEYS[key][2]}${p}=js${COOKIE_EXPIRY}`;
          }
        });

        window.acs_cbs.forEach((cb: () => void) => cb());
      };

      xhr.send();
    };

    const mergeData = (a: TrackingData, b: TrackingData): TrackingData =>
      Object.keys(LKEYS).reduce(
        (acc: TrackingData, key: string) => {
          if (b[key] && !a[key]) acc[key] = b[key];
          return acc;
        },
        { ...a }
      );

    const data = parseQueryParams();

    const pm1 = data['pm1'];
    const mid = data['mid'];
    if (pm1 && mid) {
      localStorage.setItem('mid', mid);
      localStorage.setItem('affliater_id', pm1);
      document.cookie = `mid=${encodeURIComponent(mid)}${COOKIE_EXPIRY}`;
      document.cookie = `affliater_id=${encodeURIComponent(
        pm1
      )}${COOKIE_EXPIRY}`;
    }

    if (!(IMK in data)) {
      saveCookies(data);
      return;
    }

    let durl = `${durlBase}?im=${data[IMK]}&navi=${performance.navigation.type}`;
    DKEYS.forEach((key) => {
      if (data[key]) {
        durl += `&${key}=${data[key]}`;
      }
    });

    const xhr = new XMLHttpRequest();
    xhr.open('GET', durl);

    xhr.onloadend = () => {
      if (xhr.status !== 200) return;
      try {
        const xhrData = JSON.parse(xhr.responseText) as TrackingData;

        if (PK !== 'p' && xhrData['p']) {
          xhrData[PK] = xhrData['p'];
        }
        saveCookies(mergeData(xhrData, data));
      } catch (error) {
        console.error('Failed to parse tracking data:', error);
      }
    };

    xhr.send();
  }, []);
};

export default useAcsKeep;
