import {
  AppInfo,
  Avatar,
  Button,
  Header,
  Icon,
  ListItem,
  ProfileRank,
  SettingEmail,
  SettingNotification,
  Text,
} from 'components';
import { TEXT_STRING, routes } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { useSettingStore, useUserStore } from 'store';
import { useAuth } from 'hooks';
import Banner from '../../assets/images/advertisingBanner.png';
import { useMemo } from 'react';

const COMMON_STR = TEXT_STRING.COMMON;
const MY_PAGE_STR = TEXT_STRING.MY_PAGE;

function MyPage() {
  // Hook
  const { setOpenTutorial } = useSettingStore();
  const { user } = useUserStore();
  const { logout } = useAuth();
  const navigate = useNavigate();

  // Memo
  const accountInformation = useMemo(
    () => [
      {
        route: routes.FOLLOWING,
        label: MY_PAGE_STR.LIST_FOLLOWING,
        icon: 'wifi',
      },
      {
        route: routes.FEMALE_MET,
        label: MY_PAGE_STR.FEMALE_MET,
        icon: 'doubleHeart',
      },
      {
        route: routes.ORDER_HISTORY,
        label: MY_PAGE_STR.ORDER_HISTORY,
        icon: 'paper',
      },
      {
        route: routes.PAYMENT_METHOD,
        label: MY_PAGE_STR.PAYMENT_METHOD,
        icon: 'creditCard',
      },
      {
        route: routes.BUY_POINTS,
        label: MY_PAGE_STR.BUY_POINTS,
        icon: 'teco',
        disabled: user?.isLimited,
      },
      {
        route: routes.POINT_HISTORY,
        label: MY_PAGE_STR.BUY_POINTS_HISTORY,
        icon: 'fileClock',
        disabled: user?.isLimited,
      },
      {
        route: routes.PREMIUM_PURCHASED,
        label: MY_PAGE_STR.PURCHASED_PAID_TWEET,
        icon: 'computer',
      },
    ],
    [user?.isLimited]
  );

  return (
    <div className="h-full overflow-y-auto">
      <Header
        title={user?.name || COMMON_STR.MY_PAGE}
        suffix={
          <Button
            onClick={() => navigate(routes.EDIT_MALE)}
            padding="p-3"
            className="whitespace-nowrap"
          >
            {TEXT_STRING.MALE_PAGE.EDIT_PROFILE}
          </Button>
        }
      />
      <div className="flex flex-col items-center p-4 pt-8 gap-4">
        <Avatar url={user?.avatar || ''} circle size="w-48" />
      </div>
      <div className="px-16px">
        <ProfileRank
          onRedirect={() => navigate(routes.STAR_DETAIL)}
          star={user?.total.ranking}
        />
        <div className="flex flex-col gap-12px py-20px border-b border-neutral-50">
          <img
            src={Banner}
            className="cursor-pointer"
            alt="introducing-friend"
            onClick={() => {
              navigate(routes.INTRODUCING_FRIEND);
            }}
          />
          <Text textColor="text-primary" fontSize={14} bold>
            {MY_PAGE_STR.ACCOUNT_INFO}
          </Text>
          <Text bold>{COMMON_STR.INFO}</Text>
          <Text>
            {MY_PAGE_STR.ACCOUNT_INFO}:{' '}
            <span className="ml-8">{user?.name || ''}</span>
          </Text>
          {accountInformation.map((item, index) => (
            <ListItem
              key={index}
              onClick={() => navigate(item.route)}
              text={item.label}
              prefix={
                <div>
                  <Icon icon={item.icon} color="var(--color-neutral-550)" />
                </div>
              }
              className="cursor-pointer"
              disabled={item?.disabled}
            />
          ))}
        </div>
        <div className="py-20px flex flex-col gap-3 border-b border-neutral-50">
          <Text textColor="text-primary" fontSize={14} bold>
            {COMMON_STR.SETTING}
          </Text>
          <Text bold>{COMMON_STR.PROFILE}</Text>
          <ListItem
            className="cursor-pointer"
            text={TEXT_STRING.MY_PAGE.OPEN_TUTORIAL}
            onClick={() => setOpenTutorial(true)}
            prefix={<Icon icon={'home'} color="var(--color-neutral-550)" />}
          />
          <SettingNotification />
          <SettingEmail />
        </div>
        <AppInfo />
        <div className="py-20px">
          <ListItem
            onClick={logout}
            text={TEXT_STRING.MY_PAGE.LOGOUT}
            textColor="text-error"
            prefix={<Icon icon={'logOut'} />}
          />
        </div>
      </div>
    </div>
  );
}

export default MyPage;
