import { Icon, Text } from 'components';
import React, { ReactElement } from 'react';

type Props = {
  className?: string;
  onClick?: () => void;
  prefix?: ReactElement;
  suffix?: ReactElement;
  textColor?: `text-${string}`;
  text: string | ReactElement;
  disabled?: boolean;
};

const ListItem = ({
  className = '',
  onClick,
  textColor = 'text-neutral-600',
  text = '',
  prefix,
  suffix,
  disabled = false,
}: Props): JSX.Element => {
  return (
    <div
      className={`${className} flex items-center justify-between py-8px gap-8px ${
        disabled && 'opacity-50'
      }`}
      onClick={!disabled ? onClick : () => {}}
    >
      <div className="flex flex-1 items-center gap-8px">
        {prefix}
        {typeof text === 'string' ? (
          <Text textColor={textColor}>{text}</Text>
        ) : (
          text
        )}
      </div>
      {suffix || <Icon icon={'chevronRight'} />}
    </div>
  );
};

export default ListItem;
