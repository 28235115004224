import axios from 'axios';
import {
  TCard,
  TListResult,
  TMoneyPaymentByCardBody,
  TOrderHistory,
  TResPayment,
  TVeilpayPayload,
  TVeilpayRespone,
} from 'types';

const getCardPaymentList = async (
  currentPage: number,
  pageSize: number
): Promise<{
  totalPage: number;
  currentPage: number;
  total: number;
  data: TCard[];
}> => {
  return await axios
    .get('payment/card', {
      params: {
        currentPage: currentPage,
        pageSize: pageSize,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const registerCard = async (token: string) => {
  return await axios
    .post('payment/card/register', { token })
    .then((response) => {
      return response.data;
    });
};

const updateCardDefault = async (cardId: number) => {
  return await axios
    .patch('payment/card/default', { cardId })
    .then((response) => {
      return response.data;
    });
};

const deleteCard = async (id: number) => {
  return await axios.delete(`payment/card/${id}`).then((response) => {
    return response.data;
  });
};

const checkValidAmount = async (id: number): Promise<boolean> => {
  return await axios
    .post(`payment/card/check-amount/${id}`)
    .then((response) => {
      return response.data;
    });
};

const getPaymentRequest = async (id: number) => {
  return await axios.get(`payment/${id}`).then((response) => {
    return response.data;
  });
};

const getListPendingPayment = async () => {
  return await axios.get('payment/pending').then((response) => {
    return response.data;
  });
};

const payMoney = async (
  body: TMoneyPaymentByCardBody
): Promise<TResPayment> => {
  return await axios.post('payment/pending', body).then((response) => {
    return response.data;
  });
};

const getPendingPayment = async (id: string) => {
  return await axios.get(`payment/pending/${id}`).then((response) => {
    return response.data;
  });
};

const getOrderHistory = async (
  currentPage: number,
  pageSize: number
): Promise<TListResult<TOrderHistory>> => {
  const res = await axios
    .get('payment/history', {
      params: {
        currentPage: currentPage,
        pageSize: pageSize,
      },
    })
    .then(function (response) {
      return response.data;
    });

  return res;
};

// Veilpay
const createVeilpayCustomerId = async (
  body: TVeilpayPayload
): Promise<TVeilpayRespone> => {
  const response = await axios.post('payment/veilpay/customer', body);
  return response.data;
};

export {
  getCardPaymentList,
  registerCard,
  updateCardDefault,
  deleteCard,
  getPaymentRequest,
  payMoney,
  getOrderHistory,
  getPendingPayment,
  checkValidAmount,
  createVeilpayCustomerId,
  getListPendingPayment,
};
