import Icon from 'components/Icon';
import Text from 'components/Text';
import { TEXT_STRING } from '../../constants';
import { EPaymentMethod } from 'types';
import Button from 'components/Button';
import { useMemo } from 'react';
import { useUserStore } from 'store';

type Props = {
  onChooseMethod: (paymentMethod: EPaymentMethod) => void;
  loading?: boolean;
  paymentMethod: EPaymentMethod;
  onSubmit: () => void;
  price: number;
  allowPaymentMethod?: EPaymentMethod[];
};

const MethodPayment = ({
  onChooseMethod,
  loading,
  paymentMethod,
  onSubmit,
  price,
  allowPaymentMethod,
}: Props) => {
  const { user } = useUserStore();

  const methodOptions = useMemo(
    () => [
      {
        label: TEXT_STRING.PAYMENT.CASH,
        icon: 'money',
        method: EPaymentMethod.CASH,
        visible: allowPaymentMethod?.includes(EPaymentMethod.CASH),
      },
      {
        label: TEXT_STRING.PAYMENT.POINT_ASSET,
        icon: 'teco',
        method: EPaymentMethod.POINT,
        visible: allowPaymentMethod?.includes(EPaymentMethod.POINT),
        disabled: user?.isLimited,
      },
      {
        label: TEXT_STRING.PAYMENT.CARD,
        icon: 'creditCard',
        method: EPaymentMethod.CARD,
        disabled: !price,
        visible: allowPaymentMethod?.includes(EPaymentMethod.CARD),
      },
    ],
    [allowPaymentMethod, price, user?.isLimited]
  );

  return (
    <>
      <div className="grid grid-cols-2 gap-4 my-5">
        {methodOptions.map((item) => {
          const isActive = paymentMethod === item.method;
          return item.visible ? (
            <div
              key={item.method}
              className={`basis-1/2 border rounded-md cursor-pointer ${
                isActive ? 'border-primary bg-primary-50' : ''
              } ${item.disabled && 'pointer-events-none opacity-50'}`}
              onClick={() => {
                onChooseMethod(item.method);
              }}
            >
              <div
                className={`w-5 aspect-square rounded-full ${
                  isActive
                    ? 'bg-primary ring-primary border-4 border-solid border-white'
                    : 'ring-neutral-200'
                } ring-1 cursor-pointer ml-auto mr-2 mt-2`}
              ></div>
              <div className="flex gap-3 px-6 pb-7">
                <div className="w-7 h-7">
                  <Icon icon={item.icon} />
                </div>
                <Text>{item.label}</Text>
              </div>
            </div>
          ) : null;
        })}
      </div>
      <Button
        block
        onClick={onSubmit}
        loading={loading}
        disabled={loading || !allowPaymentMethod?.includes(paymentMethod)}
      >
        {TEXT_STRING.COMMON.CONTINUE}
      </Button>
    </>
  );
};

export default MethodPayment;
