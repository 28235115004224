import { storageKeys } from '../constants';
import { initialFilterFemale } from 'helpers';
import { TFilterFemale, TFilterOptions, TPaymentPending } from 'types';
import { create } from 'zustand';

const filterLocal = localStorage.getItem(storageKeys.FILTER_FEMALE);

type State = {
  filterOptions?: TFilterOptions;
  filterFemale: TFilterFemale;
  searchScrollY: number;
  paymentPending: TPaymentPending;
};

type Actions = {
  setFilterOptions: (values: TFilterOptions) => void;
  setFilterFemale: (values: TFilterFemale) => void;
  setSearchScrollY: (value: number) => void;
  setPaymentPending: (value: TPaymentPending) => void;
};

const useAppStore = create<State & Actions>((set) => ({
  filterOptions: undefined,
  listPendingPayment: [],
  lastFetchPaymentTime: 0,
  paymentPending: {
    ids: [],
    lastFetchingTime: 0,
  },
  filterFemale: filterLocal ? JSON.parse(filterLocal) : initialFilterFemale,
  searchScrollY: 0,
  setFilterOptions: (values: TFilterOptions) =>
    set(() => ({
      filterOptions: values,
    })),
  setFilterFemale: (values: TFilterFemale) => {
    localStorage.setItem(storageKeys.FILTER_FEMALE, JSON.stringify(values));
    set(() => ({
      filterFemale: values,
    }));
  },
  setSearchScrollY: (value: number) => {
    set(() => ({ searchScrollY: value }));
  },
  setPaymentPending: (value: TPaymentPending) => {
    set(() => ({ paymentPending: value }));
  },
}));

export default useAppStore;
