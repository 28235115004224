import { EPaymentMethod, TOrderHistory } from 'types';
import ListItem from '../OrderHistoryList/ListItem';
import DetailItem from './DetailItem';
import PriceItem from './PriceItem';
import { TEXT_STRING } from '../../../constants';
import { useCallback } from 'react';

type Props = { data: TOrderHistory };
type TField = {
  label: string;
  key:
    | 'total'
    | 'prepaid'
    | 'discount'
    | 'gamesDiscount'
    | 'totalAfterDiscount'
    | 'totalAfterDiscountCardPayment';
};

const commonStr = TEXT_STRING.COMMON;
const releaseStr = TEXT_STRING.RELEASE_GIRL;

const totalFields: TField[] = [
  {
    label: releaseStr.TOTAL,
    key: 'total',
  },
  {
    label: releaseStr.PRE_PAID,
    key: 'prepaid',
  },
  {
    label: releaseStr.DISCOUNT,
    key: 'discount',
  },
];

const methodPayment: Record<string, string> = {
  [EPaymentMethod.CARD]: `${commonStr.PAYMENT_CARD}${releaseStr.HANDLING_FEE}`,
  [EPaymentMethod.CASH]: commonStr.PAYMENT_MONEY,
  [EPaymentMethod.POINT]: commonStr.PAYMENT_POINT,
};

const OrderHistoryDetail = ({ data }: Props) => {
  const TotalPrice = useCallback(() => {
    return (
      <>
        {totalFields.map(({ label, key }, index) => (
          <PriceItem
            key={index}
            label={label + ':'}
            price={+data.payment[key]}
          />
        ))}
        <PriceItem
          label={releaseStr.TOTAL + ':'}
          price={+data.payment?.totalAfterDiscount}
          show={
            +data.payment?.totalAfterDiscount >= 0 &&
            (!!data?.payment?.prepaid || !!data?.payment?.discount)
          }
        />
        {data?.methods && (
          <>
            {Object.entries(data?.methods).map(([key, value]) => (
              <PriceItem
                label={methodPayment[key] + ':'}
                price={value.amount + value?.fee}
                key={key}
                show={value.amount >= 0}
              />
            ))}
          </>
        )}
      </>
    );
  }, [data?.methods, data.payment]);

  return (
    <div>
      <ListItem data={data} />
      {data.females?.map((female) => {
        return (
          <DetailItem key={female.femaleId} data={female}>
            {data.females?.length <= 1 && <TotalPrice />}
          </DetailItem>
        );
      })}
      {/* Total */}
      {data.females?.length > 1 && (
        <div className={`my-5 p-6 bg-neutral-25 items-center`}>
          <TotalPrice />
        </div>
      )}
    </div>
  );
};

export default OrderHistoryDetail;
